import React from 'react';
import Component from "@reactions/component";
import PropTypes from 'prop-types';
import Drawer from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';
import IconButton from '@material-ui/core/IconButton';
import CategoryIcon from '@material-ui/icons/Category';
import MenuIcon from '@material-ui/icons/Menu';
import ListIcon from '@material-ui/icons/List';
import PeopleIcon from '@material-ui/icons/People';
import Orders from '../orders';
import AdDash from '../ad';
import { SideSheet, Position, } from 'evergreen-ui';
import { ToastContainer, toast } from 'react-toastify';
import NotificationsActiveIcon from '@material-ui/icons/NotificationsActive';
import GradientIcon from '@material-ui/icons/Gradient';
import Toolbar from '@material-ui/core/Toolbar';
import { makeStyles } from '@material-ui/core/styles';
import { NavLink } from 'react-router-dom';
import Login from '../../dashboard1/commonDash1/login';
import Category from '../category';
import MeetingRoomIcon from '@material-ui/icons/MeetingRoom';
import UserDash from '../Users';
import Cookies from "universal-cookie";
import host from '../../../assets/js/Host';
import axios from 'axios';
import qs from 'qs';
const cookies = new Cookies();
const drawerWidth = 240;

const useStyles = makeStyles(theme => ({
    root: {
        direction: 'rtl',
        display: 'flex',
        paddingTop: 0,
    },
    drawer: {

        paddingTop: 0,
        [theme.breakpoints.up('sm')]: {
            width: drawerWidth,
            flexShrink: 0,
        },
    },
    BTNSlideContiner: {
        height: '90vh',
        display: 'flex',
        flexDirection: 'column',
        //   alignItems: 'center',
        justifyContent: 'space-between',
    },
    menuButton: {
        marginRight: theme.spacing(2),
        zIndex: 11,
        [theme.breakpoints.up('sm')]: {
            display: 'none',
        },
    },
    BTNSlide: {
        // width:drawerWidth,
        display: 'flex',
        alignItems: 'center',
        paddingRight: theme.spacing(4),
        marginBottom: 10,
        color: '#fff',
        height: 40,
        cursor: 'pointer',
        '&:hover': {
            color: '#fff!important',
            transitionDuration: '.3s',
            transitionTimingFunction: 'linear',
            textDecoration: 'none',
        },

    },
    BTNSlideName: {
        marginRight: 15,
        fontSize: 18
    },
    toolbar: {

        marginTop: -24,
    },
    drawerPaper: {
        backgroundColor: '#ffa450',
        width: drawerWidth,
        direction: 'rtl',
    },
    content: {
        flexGrow: 1,
        // padding: theme.spacing(3),
        paddingTop: theme.spacing(3),
    },

}));

function ResponsiveDrawer(props) {
    const { container } = props;
    const classes = useStyles();
    const [mobileOpen, setMobileOpen] = React.useState(false);

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };

    const drawer = (
        <div className={classes.BTNSlideContiner}>
            <div>
                <NavLink to='/'>
                    <img src={require('../../../assets/img/logo.png')} alt='logo' style={{ width: 180, marginLeft: 40, marginBottom: 50 }} />
                </NavLink>
                <div className={classes.toolbar} />
                < NavLink activeClassName='ActiveSliderBTN' to='/CategoryDash' >
                    <div className={classes.BTNSlide}>
                        <CategoryIcon id='iconActive' />
                        <article className={classes.BTNSlideName}>الاقسام</article>
                    </div>
                </NavLink>
                <NavLink activeClassName='ActiveSliderBTN' to='/AdDash' >
                    <div className={classes.BTNSlide}>
                        <GradientIcon id='iconActive' />
                        <article className={classes.BTNSlideName}>الاعلانات</article>
                    </div>
                </NavLink>
                <NavLink activeClassName='ActiveSliderBTN' to='/OrderDash' >
                    <div className={classes.BTNSlide}>
                        <ListIcon id='iconActive' />
                        <article className={classes.BTNSlideName}>الطلبات</article>
                    </div>
                </NavLink>
                <NavLink activeClassName='ActiveSliderBTN' to='/UserDash' >
                    <div className={classes.BTNSlide}>
                        <PeopleIcon id='iconActive' />
                        <article className={classes.BTNSlideName}>المستخدمين</article>
                    </div>
                </NavLink>

                <Component initialState={{ isShown: false, title: '', body: '' }}>
                    {({ state, setState }) => (
                        <React.Fragment>
                            <SideSheet
                                isShown={state.isShown}
                                position={Position.LEFT}
                                onCloseComplete={() => setState({ isShown: false })}
                            >
                                <div margin={40}>

                                    <div id='titleAddAppBar'>ارسال اشعارات</div>
                                    <div id='inputAddContiner'>
                                        <div id={'ContinerInPut'} >
                                            <label>العنوان </label>
                                            <input autoComplete='off' type='text' placeholder='العنوان ' id='InputTExtDash1'
                                                onChange={(e) => setState({ title: e.target.value })} />
                                        </div>
                                        <div id={'ContinerInPut'}>
                                            <label>المحتوى </label>
                                            <textarea id='textAreaAdd' cols="100" placeholder="المحتوى "
                                                onChange={(e) => setState({ body: e.target.value })} />
                                        </div>
                                    </div>
                                    <div id='btnAddItemContiner'>
                                        <div id='btnAddItem' onClick={() => {
                                            axios.post(host + `dashbord/notification/`,
                                                qs.stringify({
                                                    title: state.title,
                                                    body: state.body,
                                                }), {
                                                headers: { "Content-Type": "application/x-www-form-urlencoded", token: cookies.get("AdminToken")  }
                                            }).then(response => {
                                                setState({ isShown: false })
                                                toast("تم الارسال بنجاح", {
                                                    position: "bottom-center",
                                                    autoClose: 5000,
                                                    hideProgressBar: false,
                                                    closeOnClick: true,
                                                    pauseOnHover: true,
                                                    draggable: true
                                                });
                                            }).catch(error => {
                                                console.log(error.response.data)
                                            })
                                        }}>ارسال</div>

                                    </div>


                                </div>
                            </SideSheet>
                            <div className={classes.BTNSlide} onClick={() => setState({ isShown: true })}>
                                <NotificationsActiveIcon id='iconActive' />
                                <article className={classes.BTNSlideName}>ارسال اشعارات</article>
                            </div>
                        </React.Fragment>
                    )}
                </Component>

            </div>
            <div className={classes.BTNSlide}
                onClick={() => {
                    cookies.remove("AdminToken")
                    window.location.href = '/'
                }}>
                <MeetingRoomIcon />
                <div className={classes.BTNSlideName}>تسجيل الخروج</div>
            </div>
        </div>
    );
    if (cookies.get("AdminToken")) {
        return (
            <div className={classes.root}>

                <Toolbar id='NavContinerApp'>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        edge="start"
                        onClick={handleDrawerToggle}
                        className={classes.menuButton}
                    >
                        <MenuIcon />
                    </IconButton>
                </Toolbar>
                <nav className={classes.drawer} aria-label="mailbox folders">

                    {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
                    <Hidden smUp implementation="css">

                        <Drawer
                            container={container}
                            variant="temporary"
                            anchor='right'
                            open={mobileOpen}
                            onClose={handleDrawerToggle}
                            classes={{
                                paper: classes.drawerPaper,
                            }}
                            ModalProps={{
                                keepMounted: true, // Better open performance on mobile.
                            }}
                        >
                            <div >

                                {drawer}
                            </div>
                        </Drawer>
                    </Hidden>

                    <Hidden xsDown implementation="css">

                        <Drawer
                            anchor='right'
                            classes={{
                                paper: classes.drawerPaper,
                            }}
                            variant="permanent"
                            open
                        >
                            <div style={{ marginTop: 29 }}>

                                {drawer}
                            </div>
                        </Drawer>
                    </Hidden>
                </nav>
                <main className={classes.content}>
                    <div className={classes.toolbar} />
                    {renderPage(props)}
                </main>
                <ToastContainer
                    position="bottom-left"
                    autoClose={10000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnVisibilityChange
                    draggable
                    pauseOnHover
                />
            </div>
        )
    }
    else {
        return (
            <Login />
        )
    }

}

ResponsiveDrawer.propTypes = {
    /**
     * Injected by the documentation to work in an iframe.
     * You won't need it on your project.
     */
    container: PropTypes.instanceOf(typeof Element === 'undefined' ? Object : Element),
};
const renderPage = (props) => {
    // if (props.match.path === '/Setting') {
    //     return (<Sitting />)
    // }
    if (props.match.path === '/CategoryDash') {
        return (<Category />)
    }
    else if (props.match.path === '/OrderDash') {
        return (<Orders />)
    }
    else if (props.match.path === '/AdDash') {
        return (<AdDash />)
    }
    else if (props.match.path === '/UserDash') {
        return (<UserDash />)
    }

}
export default ResponsiveDrawer;
