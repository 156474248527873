import React from 'react';
import Context from '../../assets/js/Context';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import LibraryAdd from '@material-ui/icons/LibraryAdd';
import Component from "@reactions/component";
import { SideSheet, Pane, Position, FilePicker, Icon, Spinner } from 'evergreen-ui';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import {Form} from 'react-bootstrap';
import { withStyles } from '@material-ui/styles';
import Cookies from "universal-cookie";
import host from '../../assets/js/Host';
import axios from 'axios';
import { withRouter } from 'react-router-dom'
const cookies = new Cookies();

const styles = theme => ({
    root: {
        flexGrow: 1,

    },
    AppBar: {
        backgroundColor: '#b2b2b2',
    },
    Col: {
        display: 'flex',

        justifyContent: 'center',
        alignItems: 'center',
        marginBottom: 20,
    },
    icons: {
        color: '#fff',
        marginRight: 10,
        cursor: 'pointer',
        '&:hover': {
            color: '#f8c429!important',
            transitionDuration: '.3s',
            transitionTimingFunction: 'linear',
        },
    },
    UsersHead: {
        width: '95%',
        height: 65,
        display: 'flex',
        marginRight: '2.5%',
        marginLeft: '2.5%',
        justifyContent: 'space-between',
        alignItems: 'center',
    }

})
class DenseAppBar extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            nameCate: '',
            image: [],
            spinerBtn: false,
            nameCity: '',
            nameItem: '',
            price: '',
            descItem: '',
            type:'',
        }
    }
    AddCate() {
        var header = { "Content-Type": "application/x-www-form-urlencoded", token: cookies.get("AdminToken") };

        let formData = new FormData();
        formData.append("name", this.state.nameCate);
        formData.append("file", this.state.image[0]);
        axios({ url: host + `category/`, method: "POST", data: formData, headers: header })
            .then(response => {
                window.location.reload();
                this.setState({ spinerBtn: false })

            })
            .catch(error => {
                console.log(error)
                this.setState({ spinerBtn: false })
            })
    }
    AddItemOffice() {
        var header = { "Content-Type": "application/x-www-form-urlencoded", token: cookies.get("AdminToken") };

        let formData = new FormData();
        formData.append("name", this.state.nameItem);
        formData.append("price", this.state.price);
        formData.append("type", this.state.type);
        formData.append("body", 'body');
        formData.append("description", this.state.descItem);
        formData.append("category_id", this.props.CAtid);
        formData.append("file", this.state.image[0]);
        axios({ url: host + `items/add`, method: "POST", data: formData, headers: header })
            .then(response => {
                window.location.reload();
                this.setState({ spinerBtn: false })

            })
            .catch(error => {
                console.log(error)
                this.setState({ spinerBtn: false })
            })
    }

    AddBanner() {
        var header = { "Content-Type": "application/x-www-form-urlencoded", token: cookies.get("AdminToken") };

        let formData = new FormData();
        formData.append("file", this.state.image[0]);
        axios({ url: host + `dashbord/banners`, method: "POST", data: formData, headers: header })
            .then(response => {
                window.location.reload();
                this.setState({ spinerBtn: false })

            })
            .catch(error => {
                console.log(error)
                this.setState({ spinerBtn: false })
            })
    }
    render() {

        const { classes } = this.props;
        return (
            <Context.Consumer>
                {ctx => {
                    return (
                        <div className={classes.root}>

                            <AppBar position="static" className={classes.AppBar}>
                                <Toolbar variant="dense">

                                    <Component initialState={{ isShown: false }}>
                                        {({ state, setState }) => (
                                            <React.Fragment>
                                                <div id='AppbarContiner' >
                                                    {this.props.page === 'itemOffice' ? (
                                                        <Link to='/CategoryDash'>
                                                            <Icon icon="circle-arrow-left" size={30} color="#fff"
                                                                style={{ cursor: 'pointer' }} />
                                                        </Link>) : null}
                                                    <SideSheet
                                                        position={Position.LEFT}
                                                        isShown={state.isShown}
                                                        onCloseComplete={() => setState({ isShown: false })}
                                                    >
                                                        <Pane margin={40}>

                                                            <React.Fragment  >

                                                                {/* ////////////////////// category ////////////////////// */}
                                                                {this.props.page === 'categoryDash' ? (
                                                                    <div>
                                                                        <div id='titleAddAppBar'>اضافة قسم</div>
                                                                        <div id='inputAddContiner'>
                                                                            <div id={'ContinerInPut'} >
                                                                                <label>اسم القسم </label>
                                                                                <input autoComplete='off' type='text' placeholder='اسم القسم ' id='InputTExtDash1'
                                                                                    onChange={(e) => this.setState({ nameCate: e.target.value })} />
                                                                            </div>
                                                                            <div id={'ContinerInPut'}>
                                                                                <label>اختر صورة</label>
                                                                                <FilePicker multiple id='InputTExtDash2' onChange={file => { this.setState({ image: file }) }} />
                                                                            </div>
                                                                        </div>
                                                                        <div id='btnAddItemContiner'>
                                                                            {!this.state.spinerBtn ? (
                                                                                <div id='btnAddItem' onClick={() => {
                                                                                    this.setState({ spinerBtn: true })
                                                                                    this.AddCate()
                                                                                }}>اضافه</div>

                                                                            ) : (
                                                                                <div id='btnAddItem'><Spinner size={24} /></div>

                                                                            )}
                                                                        </div>

                                                                    </div>
                                                                ) : null}
                                                                {/* ////////////////////// itemOffice ////////////////////// */}
                                                                {this.props.page === 'itemOffice' ? (
                                                                    <div>
                                                                        <div id='titleAddAppBar'>اضافة مادة</div>
                                                                        <div id='inputAddContiner'>
                                                                            <div id={'ContinerInPut'} >
                                                                                <label>اسم المادة </label>
                                                                                <input autoComplete='off' type='text' placeholder='اسم المادة ' id='InputTExtDash1'
                                                                                    onChange={(e) => this.setState({ nameItem: e.target.value })} />
                                                                            </div>
                                                                            <div id={'ContinerInPut'} >
                                                                                <label>السعر</label>
                                                                                <input autoComplete='off' type='number' placeholder='السعر' id='InputTExtDash1'
                                                                                    onChange={(e) => this.setState({ price: e.target.value })} />
                                                                            </div>
                                                                            <div id={'ContinerInPut'}>
                                                                                <label>النوع</label>
                                                                                <Form.Control as="select" id='InputTExtDash1'
                                                                                    onChange={(even) => {
                                                                                        if (even.target.value !== 'Select') {
                                                                                            setState({ type: even.target.value })
                                                                                        }
                                                                                    }}>
                                                                                    <option value='Select'>اختيار</option>
                                                                                    <option value={1}>كغ</option>
                                                                                    <option value={0} >قطعة</option>
                                                                                </Form.Control>
                                                                            </div>
                                                                            <div id={'ContinerInPut'}>
                                                                                <label>التفاصيل </label>
                                                                                <textarea id='textAreaAdd' cols="100" placeholder="التفاصيل "
                                                                                    onChange={(e) => this.setState({ descItem: e.target.value })} />
                                                                            </div>
                                                                            <div id={'ContinerInPut'}>
                                                                                <label>اختر صورة</label>
                                                                                <FilePicker multiple id='InputTExtDash2' onChange={file => { this.setState({ image: file }) }} />
                                                                            </div>
                                                                        </div>
                                                                        <div id='btnAddItemContiner'>
                                                                            {!this.state.spinerBtn ? (
                                                                                <div id='btnAddItem' onClick={() => {
                                                                                    this.setState({ spinerBtn: true })
                                                                                    this.AddItemOffice()
                                                                                }}>اضافه</div>

                                                                            ) : (
                                                                                <div id='btnAddItem'><Spinner size={24} /></div>

                                                                            )}
                                                                        </div>

                                                                    </div>
                                                                ) : null}
                                                                {/* ////////////////////// bannerDash ////////////////////// */}
                                                                {this.props.page === 'bannerDash' ? (
                                                                    <div>
                                                                        <div id='titleAddAppBar'>اضافة اعلان</div>
                                                                        <div id='inputAddContiner'>
                                                                            <div id={'ContinerInPut'}>
                                                                                <label>اختر صورة</label>
                                                                                <FilePicker multiple id='InputTExtDash2' onChange={file => { this.setState({ image: file }) }} />
                                                                            </div>
                                                                        </div>
                                                                        <div id='btnAddItemContiner'>
                                                                            {!this.state.spinerBtn ? (
                                                                                <div id='btnAddItem' onClick={() => {
                                                                                    this.setState({ spinerBtn: true })
                                                                                    this.AddBanner()
                                                                                }}>اضافه</div>

                                                                            ) : (
                                                                                <div id='btnAddItem'><Spinner size={24} /></div>

                                                                            )}
                                                                        </div>

                                                                    </div>
                                                                ) : null}

                                                            </React.Fragment  >
                                                        </Pane>
                                                    </SideSheet>
                                                    {(this.props.page === 'categoryDash' || this.props.page === 'bannerDash' || this.props.page === 'itemOffice' || this.props.page === 'banneroffice') ? (
                                                        <LibraryAdd className={classes.icons} onClick={() => setState({ isShown: true })} />
                                                    ) : this.props.page === 'office' ? (
                                                        <LibraryAdd className={classes.icons} onClick={() => {
                                                            window.location.href = `/Addoffice/${this.props.match.params.id}`

                                                        }} />
                                                    ) : null}

                                                </div>

                                            </React.Fragment>
                                        )}
                                    </Component>


                                </Toolbar>
                            </AppBar>
                        </div>
                    );
                }}
            </Context.Consumer>
        )
    }

}
DenseAppBar.propTypes = {
    classes: PropTypes.object.isRequired,
}
export default withRouter(withStyles(styles)(DenseAppBar))
