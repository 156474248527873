import React from 'react';
import Avatar from '@material-ui/core/Avatar';
import CssBaseline from '@material-ui/core/CssBaseline';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import 'react-phone-input-2/lib/style.css'
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/styles';
import { withRouter } from 'react-router-dom';
import imagg from '../../assets/img/logo.png'

const styles = theme => ({
  root: {
    height: '100vh',
  },
  image: {
    backgroundColor: '#ffa450',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100vh',
    width: '100%',
  },
  paper: {
    height:'100vh',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent:'center',
  },
  avatar: {
    margin: 10,
    backgroundColor: '#ffa450',
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    height: '80Vh',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: 30,
  },
  submit: {
    marginTop: 50,
    width: 200,
  },
});

class SignInSide extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      timer: 60,
      activeCode: '',
      spinBtn: false,
    }
  }

  render() {
  
    const { classes } = this.props;
    return (
      <Grid container component="main" className={classes.root}>
        <CssBaseline />
        <Grid item xs={false} md={7} lg={7} className={classes.image} id='hideenlogo' >
          <img style={{width:'50%'}} src={imagg} alt='logo' />
        </Grid>
        <Grid item xs={12} md={5} lg={5} component={Paper} elevation={6} square>
          <div className={classes.paper}>
            <Avatar className={classes.avatar}>
              <LockOutlinedIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
              الحساب معطل
            </Typography>
            <div className={classes.form} noValidate>


              <h3>تم تعطيل الحساب</h3>

            </div>
          </div>
        </Grid>
      </Grid>
    );
  }
}
SignInSide.propTypes = {
  classes: PropTypes.object.isRequired,
}
export default withRouter(withStyles(styles)(SignInSide))