/* eslint-disable jsx-a11y/heading-has-content */
import React from 'react';
import Context from '../../assets/js/Context';
import Cookies from "universal-cookie";
import host from '../../assets/js/Host';
import axios from 'axios';
import { Icon } from 'evergreen-ui';
import { ToastContainer, toast } from 'react-toastify';
import { Row, Col } from 'react-bootstrap';
import Lottie from 'lottie-react-web'
import animation from '../../assets/json/tk.json';
import Navbar from '../common/navbar';
import Footer from '../common/footer';
import qs from 'qs';
import Login from '../common/login';
import Disable from '../common/Disabled';
import { Link } from 'react-router-dom'

const cookies = new Cookies();

class Home extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            spin: false,
            name: '',
            cove_photo: '',
            description: '',
            price: '',
            type: '',
            Dots: '',
            Num:1,
        }
    }

    componentDidMount() {
        var header = { "Content-Type": "application/x-www-form-urlencoded", Authorization: cookies.get("token") };
        axios.get(host + `items/item/${this.props.match.params.id}`, { headers: header })
            .then(res => {
                // console.log(res.data.data.item);
                this.setState({
                    name: res.data.data.item.name,
                    cove_photo: host + 'files/' + res.data.data.item.cove_photo,
                    description: res.data.data.item.description,
                    price: res.data.data.item.price,
                    type: res.data.data.item.type,
                    spin: true
                })
            })
            .catch(error => {
                console.log(error)
                this.setState({ spin: true })
            })


    }
    ADDTOCART() {


    }
    render() {
        if (cookies.get("token")) {
            if (this.state.spin) {
                return (
                    <Context.Consumer>
                        {ctx => {
                             if (ctx.value.isActive) {
                            return (
                                <div style={{ backgroundColor: '#ecf0f1' }}>
                                    <Navbar />
                                    <div style={{ display: 'flex', justifyContent: 'flex-start', width: '100%', padding: 20 }}>
                        <Link to={`/ItemCate/${localStorage.getItem('IdCAT')}`}>
                            <Icon icon="circle-arrow-left" size={30} color="#ffa450"
                                style={{ cursor: 'pointer' }} />
                        </Link>
                    </div>
                                    <div id='continerItemCate'>
                                        <Row id='shadowROOw'>
                                            <Col sm={12} md={12} lg={6} id='ColitemDDess1'>
                                                <img src={this.state.cove_photo} alt='img' id='mgeitemOnly' />
                                            </Col>
                                            <Col sm={12} md={12} lg={6} id='ColitemDDess2'>
                                                <div >
                                                    <h5 style={{ color: '#ffa450' }}>{this.state.name}</h5>
                                                    <p>{this.state.description}</p>
                                                </div>
                                                <div id='SHOPCONTINER'>
                                                    <div id='PLUSMIN'>
                                                      
                                                        <div style={{ display: 'flex', justifyContent: 'center', width: 100 }}>
                                                            <input placeholder='1' type='number' min={1} id='count' style={{ marginLeft: 10 }} className='INPUtCOUnt'
                                                            onChange={(e)=>this.setState({Num:e.target.value})}/>
                                                            <h6 style={{paddingTop:7}}>{this.state.type === 1 ? 'كغ' : 'قطعة'}</h6> </div>
                                                        
                                                    </div>
                                                    <div id='SHOpBTNCON' onClick={() => {

                                                        axios.post(host + `users/cart/add`,
                                                            qs.stringify({ item_id: this.props.match.params.id, count: this.state.Num }), {
                                                            headers: {
                                                                "Content-Type": "application/x-www-form-urlencoded", Authorization: cookies.get("token")
                                                            }
                                                        }).then(response => {
                                                            this.componentDidMount();
                                                            toast('تمت الاضافة الى السله', {
                                                                position: "bottom-center",
                                                                autoClose: 5000,
                                                                hideProgressBar: false,
                                                                closeOnClick: true,
                                                                pauseOnHover: true,
                                                                draggable: true
                                                            });
                                                            setTimeout(() => {
                                                                ctx.action.DotsNum()
                                                            }, 200);

                                                        }).catch(error => { console.log(error) })
                                                    }}>
                                                        <h6>اضف الى السلة</h6>
                                                        <h6 id='priceR'>{this.state.price * this.state.Num} IQD</h6>
                                                    </div>
                                                </div>

                                            </Col>
                                        </Row>
                                    </div>
                                    <Footer />
                                    <ToastContainer
                                        position="bottom-center"
                                        autoClose={5000}
                                        hideProgressBar={false}
                                        newestOnTop={false}
                                        closeOnClick
                                        rtl={false}
                                        pauseOnVisibilityChange
                                        draggable
                                        pauseOnHover
                                    />
                                </div>
                            )
                        }
                        else {
                            return (
                                <Disable />
                            )
                        }
                        }}
                    </Context.Consumer>
                )
            }
            else {
                return (
                    <div style={{ width: '100%', height: 'calc(100vh - 60px)', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <Lottie style={{ width: 300 }}
                            options={{
                                animationData: animation
                            }}
                        />
                    </div>
                )
            }
        }
        else {
            return (
                <Login />
            )
        }
    }

}
export default Home