import React from 'react';
import Avatar from '@material-ui/core/Avatar';
import CssBaseline from '@material-ui/core/CssBaseline';
import Paper from '@material-ui/core/Paper';
import SupervisedUserCircle from '@material-ui/icons/SupervisedUserCircle';
import Grid from '@material-ui/core/Grid';
import { Icon, Spinner } from 'evergreen-ui';
import Typography from '@material-ui/core/Typography';
import 'react-phone-input-2/lib/style.css'
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/styles';
import host from '../../assets/js/Host';
import axios from 'axios';
import { withRouter } from 'react-router-dom';
import imagg from '../../assets/img/logo.png'
import qs from 'qs';
import { Link } from 'react-router-dom'
import Cookies from "universal-cookie";
const cookies = new Cookies();
const styles = theme => ({
    root: {
        height: '100vh',
    },
    image: {
        backgroundColor: '#ffa450',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100vh',
        width: '100%',
    },
    paper: {
        height: '100vh',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
    },
    avatar: {
        margin: 10,
        backgroundColor: '#ffa450',
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        height: '80Vh',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        marginTop: 30,
    },
    submit: {
        marginTop: 50,
        width: 200,
    },
});

class SignInSide extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            name: '',
            phone: '',
            government: '',
            city: '',
            spinBtn: false,
        }
    }
    componentDidMount() {
        var header = { "Content-Type": "application/x-www-form-urlencoded", Authorization: cookies.get("token") };
        axios.get(host + `users/auth/profile`, { headers: header })
            .then(res => {
                // console.log(res.data.data.profile);
                this.setState({
                    name: res.data.data.profile.name,
                    phone: res.data.data.profile.phone,
                    government: res.data.data.profile.government,
                    city: res.data.data.profile.city,

                })
            })
            .catch(error => {
                console.log(error)
                this.setState({ spin: true })
            })
    }
    Edit() {
        axios.put(host + `users/auth/proile/edit`,
            qs.stringify({
                name: this.state.name,
                phone: this.state.phone,
                government: this.state.government,
                city: this.state.city,
            }), {
            headers: {
                "Content-Type": "application/x-www-form-urlencoded", Authorization: cookies.get("token")
            }
        }).then(response => {
            // console.log(response.data.data);
            this.setState({ spinBtn: false })
            window.location.href = "/Account";


        }).catch(error => {
            this.setState({ spinBtn: false })

            console.log(error)
        })


            ;
    }
    render() {

        const { classes } = this.props;
        return (
            <Grid container component="main" className={classes.root}>
                <CssBaseline />
                <Grid item xs={false} md={7} lg={7} className={classes.image} id='hideenlogo' >
                    <img style={{ width: '50%' }} src={imagg} alt='logo' />
                </Grid>
                <Grid item xs={12} md={5} lg={5} component={Paper} elevation={6} square>
                    <div style={{ display: 'flex', justifyContent: 'flex-start', width: '100%', padding: 20 }}>
                        <Link to='/Account'>
                            <Icon icon="circle-arrow-left" size={30} color="#ffa450"
                                style={{ cursor: 'pointer' }} />
                        </Link>
                    </div>
                    <div className={classes.paper}>

                        <Avatar className={classes.avatar}>
                            <SupervisedUserCircle />
                        </Avatar>
                        <Typography component="h1" variant="h5">
                            تعديل المعلومات
                        </Typography>
                        <div className={classes.form} noValidate>
                            <div id='field1_ah'>
                                <input autoComplete='off' type='text' placeholder=' اسم المتجر' id='InputTExtDash22' style={{ width: 260, textAlign: 'center', }}
                                    value={this.state.name} onChange={(e) => this.setState({ name: e.target.value })} />
                            </div>
                            <div id='field1_ah'>
                                <input autoComplete='off' type='text' placeholder=' رقم الهاتف ' id='InputTExtDash22' style={{ width: 260, textAlign: 'center', }}
                                    value={this.state.phone} onChange={(e) => this.setState({ phone: e.target.value })} />
                            </div>
                            <div id='field1_ah'>
                                <input autoComplete='off' type='text' placeholder=' المحافظه ' id='InputTExtDash22' style={{ width: 260, textAlign: 'center', }}
                                    value={this.state.government} onChange={(e) => this.setState({ government: e.target.value })} />
                            </div>
                            <div id='field1_ah'>
                                <input autoComplete='off' type='text' placeholder=' المنطقه ' id='InputTExtDash22' style={{ width: 260, textAlign: 'center', }}
                                    value={this.state.city} onChange={(e) => this.setState({ city: e.target.value })} />
                            </div>
                            <div id='log1_ah'>
                                {!this.state.spinBtn ? (
                                    <div id='log_ah' onClick={(e) => {
                                        this.setState({ spinBtn: true })
                                        this.Edit()
                                    }}>تعديل</div>
                                ) : (
                                    <div id='log_ah' ><Spinner size={24} /></div>
                                )}
                            </div>

                        </div>
                    </div >
                </Grid >
            </Grid >
        );
    }
}
SignInSide.propTypes = {
    classes: PropTypes.object.isRequired,
}
export default withRouter(withStyles(styles)(SignInSide))