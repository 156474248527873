import React from 'react';
import Component from "@reactions/component";
import Context from '../../assets/js/Context';
import Cookies from "universal-cookie";
import host from '../../assets/js/Host';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import { Row, Col, Form } from 'react-bootstrap';
import Lottie from 'lottie-react-web'
import animation from '../../assets/json/tk.json';
import Navbar from '../common/navbar';
import qs from 'qs';
import { Spinner, Pane, Dialog } from 'evergreen-ui';
import Disable from '../common/Disabled';
const cookies = new Cookies();

class Home extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            spin: false,
            offices: [],
            price: '',
            empt: false,
        }
    }
    componentDidMount() {
        var header = { "Content-Type": "application/x-www-form-urlencoded", Authorization: cookies.get("token") };
        axios.get(host + `users/cart/`, { headers: header })
            .then(res => {
                // console.log(res.data.data.my_cart);
                if (res.data.data.my_cart.length >= 1) {

                    // console.log(res.data.data.my_cart);
                    this.setState({
                        spin: true,
                        offices: res.data.data.my_cart,
                        price: res.data.data.price,
                        empt: false,
                    })
                }
                else {
                    this.setState({
                        spin: true,
                        empt: true,
                        offices: [],
                    })
                }
            })
            .catch(error => {
                console.log(error)
                this.setState({ spin: true })
            })


    }

    render() {
        if (this.state.spin) {
            return (
                <Context.Consumer>
                    {ctx => {
                        if (ctx.value.isActive) {
                            return (
                                <div style={{ backgroundColor: '#ecf0f1' }}>
                                    <Navbar />

                                    <div id='continerItemCate'>
                                        <div style={this.state.empt ? { display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%', height: 100 } : { display: 'none' }}>
                                            <h4>السلة فارغة</h4>
                                        </div>
                                        <Row style={{ margin: 0, padding: 0, direction: 'rtl' }}>
                                            {this.state.offices.map(office =>
                                                <Col key={office.id} id='ColCate' xs={6} sm={6} md={4} lg={3} xl={2}>
                                                    <div id='sliderCateImgContiner1' onClick={() => localStorage.setItem('page', 'itemCate')} style={{ height: 230 }}>

                                                        <div id='ImgBestOffers'>
                                                            <img id='ImgBestOffers'
                                                                src={host + 'files/' + office.item_photo} style={{ cursor: 'pointer' }} alt='cate' />
                                                        </div>
                                                        <div style={{ padding: 5, textAlign: 'center' }}>
                                                            <h6 style={{ direction: 'rtl', color: '#ffa450', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>{office.item_name}</h6>
                                                            <div id='PRiCountCON'>

                                                                <h6>{office.count}</h6>
                                                                <h6>{office.item_price * office.count} IQD</h6>
                                                            </div>
                                                            <div id='PRiCountCON1' onClick={() => {
                                                                var header = { "Content-Type": "application/x-www-form-urlencoded", Authorization: cookies.get("token") };
                                                                axios.delete(host + `users/cart/${office.id}`, { headers: header })
                                                                    .then(response => {
                                                                        this.componentDidMount();
                                                                        setTimeout(() => {
                                                                            ctx.action.DotsNum()
                                                                        }, 200);
                                                                    })
                                                                    .catch(error => { console.log(error) })
                                                            }}>
                                                                حذف
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Col>
                                            )}

                                        </Row>

                                        <div id='LineS' />
                                        {this.state.empt ? null : (
                                            <div style={{ backgroundColor: '#fff', direction: 'rtl', display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%', height: 100, }}>

                                                <Component initialState={{ isShown: false,Time:'',notes:'' }}>
                                                    {({ state, setState }) => (
                                                        <Pane >
                                                            <Dialog 
                                                                isShown={state.isShown}
                                                                hasHeader={false}
                                                                
                                                                onCloseComplete={() => setState({ isShown: false })}
                                                                confirmLabel="ارسال"
                                                                cancelLabel="الغاء"
                                                                onCancel={() => {
                                                                    setState({ isShown: false })
                                                                    document.getElementById("SHOpBTNCON").style.display = 'flex';
                                                                    document.getElementById("SHOpBTNCON1").style.display = 'none';
                                                                }}
                                                                onConfirm={() => {
                                                                    setState({ isShown: false })
                                                                    axios.post(host + `orders/new/`,
                                                                        qs.stringify({ notes: state.notes,time:state.Time }), {
                                                                        headers: {
                                                                            "Content-Type": "application/x-www-form-urlencoded", Authorization: cookies.get("token")
                                                                        }
                                                                    }).then(response => {
                                                                        toast('تمت عمليه الشراء', {
                                                                            position: "bottom-center",
                                                                            autoClose: 5000,
                                                                            hideProgressBar: false,
                                                                            closeOnClick: true,
                                                                            pauseOnHover: true,
                                                                            draggable: true
                                                                        });
                                                                        this.componentDidMount();
                                                                        setTimeout(() => {
                                                                            ctx.action.DotsNum()
                                                                        }, 200);
                                                                        document.getElementById("SHOpBTNCON").style.display = 'flex';
                                                                        document.getElementById("SHOpBTNCON1").style.display = 'none';

                                                                    }).catch(error => { console.log(error) })
                                                                }}
                                                            >
                                                                <div id='inputAddContiner' style={{height:400}}>
                                                                    
                                                                        <textarea id='textAreaAdd' cols="100" placeholder="الملاحضات "
                                                                          style={{width:'100%',height:120,textAlign:'right'}}  
                                                                          onChange={(e) =>   setState({ notes: e.target.value })} />
                                                                   
                                                                        <Form.Control as="select" id='InputTExtDash1'
                                                                        style={{width:'100%',textAlign:'right',marginTop:40}} 
                                                                            onChange={(even) => {
                                                                                if (even.target.value !== 'Select') {
                                                                                    setState({ Time: even.target.value })
                                                                                }
                                                                            }}>
                                                                            <option value='Select'>وقت الاستلام</option>
                                                                            <option value={'12 hour'}>12 hour</option>
                                                                            <option value={'1 day'} >1 day</option>
                                                                            <option value={'2 day'} >2 day</option>
                                                                            <option value={'3 day'} >3 day</option>
                                                                            <option value={'4 day'} >4 day</option>
                                                                        </Form.Control>
                                                                </div>
                                                            </Dialog>
                                                            <div id='SHOpBTNCON' style={{ backgroundColor: '#3368a9' }}
                                                                onClick={() => {
                                                                    setState({ isShown: true })
                                                                    document.getElementById("SHOpBTNCON").style.display = 'none';
                                                                    document.getElementById("SHOpBTNCON1").style.display = 'flex';


                                                                }} >
                                                                <h6>اتمام الشراء</h6>
                                                                <h6 id='priceR'>{this.state.price} IQD</h6>
                                                            </div>
                                                        </Pane>
                                                    )}
                                                </Component>


                                                <div id='SHOpBTNCON1' style={{ backgroundColor: '#6ab8e6', display: 'none', justifyContent: 'center' }} >
                                                    <Spinner />
                                                </div>
                                            </div>)}

                                    </div>
                                    <ToastContainer
                                        position="bottom-center"
                                        autoClose={5000}
                                        hideProgressBar={false}
                                        newestOnTop={false}
                                        closeOnClick
                                        rtl={false}
                                        pauseOnVisibilityChange
                                        draggable
                                        pauseOnHover
                                    />
                                </div>
                            )
                        }
                        else {
                            return (
                                <Disable />
                            )
                        }
                    }}
                </Context.Consumer>
            )
        }
        else {
            return (
                <div style={{ width: '100%', height: 'calc(100vh - 60px)', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <Lottie style={{ width: 300 }}
                        options={{
                            animationData: animation
                        }}
                    />
                </div>
            )
        }
    }
}
export default Home