import React from 'react';
import PersonIcon from '@material-ui/icons/Person';
import Context from '../../assets/js/Context';
import SupervisedUserCircle from '@material-ui/icons/SupervisedUserCircle';
import LockIcon from '@material-ui/icons/Lock';
import MeetingRoomIcon from '@material-ui/icons/MeetingRoom';
import CssBaseline from '@material-ui/core/CssBaseline';
import HomeIcon from '@material-ui/icons/Home';
import ListIcon from '@material-ui/icons/List';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/styles';
import Cookies from "universal-cookie";
import host from '../../assets/js/Host';
import axios from 'axios';
import { withRouter } from 'react-router-dom';
import imagg from '../../assets/img/logo.png'
import Disable from './Disabled';
const cookies = new Cookies();

const styles = theme => ({
    root: {
        height: '100vh',
    },
    image: {
        backgroundColor: '#ffa450',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100vh',
        width: '100%',
    },
    paper: {
        direction: 'rtl',
        height: '100vh',
        width: '100%',
        // margin: theme.spacing(8, 4),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
    },

});

class SignInSide extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            spinBtn: false,
            edit: false,
            name: '',
            address: '',
            image: [],
            photo: '',
            phone: '',
            uuid: '',
        }
    }
    componentDidMount() {
        var header = { "Content-Type": "application/x-www-form-urlencoded", Authorization: cookies.get("token") };
        axios.get(host + `users/auth/profile`, { headers: header })
            .then(res => {
                // console.log(res.data.data.profile);
                this.setState({
                    name: res.data.data.profile.name,
                    phone: res.data.data.profile.phone,
                    uuid: res.data.data.profile.uuid,
                    address: res.data.data.profile.government + ' / ' + res.data.data.profile.city,
                    photo: res.data.data.profile.photo,

                    spin: true
                })
            })
            .catch(error => {
                console.log(error)
                this.setState({ spin: true })
            })
    }
    editProfile() {
        var header = { "Content-Type": "application/x-www-form-urlencoded", Authorization: cookies.get("token") };
        let formData = new FormData();
        formData.append("name", this.state.name);
        formData.append("address", this.state.address);
        formData.append("file", this.state.image[0]);
        axios({ url: host + `users/auth/profile`, method: "PUT", data: formData, headers: header })
            .then(res => {
                // console.log(res.data);
                window.location.reload();
                this.setState({ spinerBtn: false })
            })
            .catch(error => {
                console.log(error)
                this.setState({ spinerBtn: false })
            })
    }
    render() {
        const { classes } = this.props;
        return (
            <Context.Consumer>
                {ctx => {
                    if (ctx.value.isActive) {
                        return (
                            <React.Fragment>
                                <Grid container component="main" className={classes.root}>
                                    <CssBaseline />
                                    <Grid item xs={false} md={7} lg={7} className={classes.image} id='hideenlogo' >
                                        <img style={{ width: '50%' }} src={imagg} alt='logo' />
                                    </Grid>
                                    <Grid item xs={12} md={5} lg={5} component={Paper} elevation={6} square>
                                        <div className={classes.paper}>

                                            <div className={classes.paper}>
                                                <div id='userImageAccount' style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', backgroundColor: '#ffa450', color: '#fff' }}>
                                                    <PersonIcon style={{ height: 60, width: 60 }} />
                                                </div>

                                                <div id='contentAcccountContiner'>
                                                    <div id='inputAccountShow'  >{this.state.name} </div>
                                                </div>
                                                <div id='contentAcccountContiner'>
                                                    <div id='inputAccountShow'  >{this.state.phone} </div>
                                                </div>

                                                <div id='contentAcccountContiner'>
                                                    <div id='inputAccountShow' >{this.state.address}</div>
                                                </div>
                                                <div id='SIGNOUTBTN' style={{ backgroundColor: '#ffa450' }} onClick={() => window.location.href = '/'}>
                                                    <HomeIcon />
                                                    <div style={{ marginRight: 5 }}><h6 style={{ paddingTop: 10, fontSize: 20 }}>الرئيسية</h6></div>
                                                    <div></div>
                                                </div>
                                                <div id='SIGNOUTBTN' style={{ backgroundColor: '#5ad78e' }} onClick={() => window.location.href = '/List'}>
                                                    <ListIcon />
                                                    <div style={{ marginRight: 5 }}><h6 style={{ paddingTop: 10, fontSize: 20 }}>سجل الطلبات</h6></div>
                                                    <div></div>
                                                </div>
                                                <div id='SIGNOUTBTN' style={{ backgroundColor: '#b4a89d' }} onClick={() => window.location.href = '/EditProfile'}>
                                                    <SupervisedUserCircle />
                                                    <div style={{ marginRight: 5 }}><h6 style={{ paddingTop: 10, fontSize: 20 }}>تعديل المعلومات</h6></div>
                                                    <div></div>
                                                </div>
                                                <div id='SIGNOUTBTN' style={{ backgroundColor: '#7fa2e9' }} onClick={() => window.location.href = '/ChangePassword'}>
                                                    <LockIcon />
                                                    <div style={{ marginRight: 5 }}><h6 style={{ paddingTop: 10, fontSize: 20 }}>تغيير كلمه المرور</h6></div>
                                                    <div></div>
                                                </div>
                                                <div id='SIGNOUTBTN' onClick={() => {
                                                    cookies.remove("token")
                                                    cookies.remove("AdminToken")
                                                    cookies.remove("OfficeToken")
                                                    window.location.href = '/'
                                                }}>
                                                    <MeetingRoomIcon />
                                                    <div style={{ marginRight: 5 }}><h6 style={{ paddingTop: 10, fontSize: 20 }}>تسجيل الخروج</h6></div>
                                                    <div></div>
                                                </div>
                                            </div>
                                        </div>
                                    </Grid>
                                </Grid>
                            </React.Fragment>
                        )
                    }
                    else {
                        return (
                            <Disable />
                        )
                    }
                }}
            </Context.Consumer>
        );
    }
}
SignInSide.propTypes = {
    classes: PropTypes.object.isRequired,
}
export default withRouter(withStyles(styles)(SignInSide))