import React from 'react';
import ScrollToTop from './assets/js/scrolToTop';
import Context from './assets/js/Context';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import NotFound from './assets/js/NotFound';
import Login from './component/common/login';
import LoginDash from './component/dashboard1/commonDash1/login';
import Signup from './component/common/signUp';
import Dash1 from './component/dashboard1/commonDash1/sideBar';
import Office from './component/dashboard1/items';
import AddOffice from './component/dashboard1/commonDash1/addOffice';
import Home from './component/website/home';
import ItemCate from './component/website/itemCate';
import ItemDesc from './component/website/itemDesc';

import Disabled from './component/common/Disabled';
import ChangePassword from './component/common/ChangePassword';
import EditProfile from './component/common/EditProfile';
import Account from './component/common/account';
import Shop from './component/website/cartShop';
import List from './component/website/List';
import Cookies from "universal-cookie";
import host from './assets/js/Host';
import axios from 'axios';
import '@brainhubeu/react-carousel/lib/style.css';
import 'react-toastify/dist/ReactToastify.css';
import './assets/css/login.css';
import './App.css';
const cookies = new Cookies();
class App extends React.Component {
  constructor() {
    super();
    this.state = {
      Dots: '',
      isActive: true,
    }
  }
  componentDidMount() {
    if (cookies.get("token")) {
      var header = { "Content-Type": "application/x-www-form-urlencoded", Authorization: cookies.get("token") };
      axios.get(host + `users/cart/`, { headers: header })
        .then(res => {
   
          this.setState({
            Dots: res.data.data.my_cart.length,
            isActive:res.data.data.isActive,
          })
        })
        .catch(error => {
          console.log(error)
          this.setState({ spin: true })
        })
    }
  }
  render() {
    return (
      <div >
        <Context.Provider value={{
          value: this.state, action: {
            DotsNum: () => {
              this.componentDidMount()
            },
          }
        }}>
          <div className="App">
            <BrowserRouter>
              <ScrollToTop>
                <Switch>
                  <Route exact path='/' component={Home} />
                  <Route path='/ItemCate/:id' component={ItemCate} />
                  <Route path='/ItemDesc/:id' component={ItemDesc} />
                  <Route path='/Account' component={Account} />
                  <Route path='/Shop' component={Shop} />
                  <Route path='/List' component={List} />
                  <Route path='/Disabled' component={Disabled} />
                  <Route path='/ChangePassword' component={ChangePassword} />
                  <Route path='/EditProfile' component={EditProfile} />
                  <Route path='/login' component={Login} />
                  <Route path='/LoginDash' component={LoginDash} />
                  <Route path='/signup' component={Signup} />
                  <Route path='/CategoryDash' component={Dash1} />
                  <Route path='/OrderDash' component={Dash1} />
                  <Route path='/AdDash' component={Dash1} />
                  <Route path='/UserDash' component={Dash1} />
                  <Route path='/officeDash/:id' component={Office} />
                  <Route path='/Addoffice/:id' component={AddOffice} />
                  <Route path='/NotFound' component={NotFound} />
                  <Route exact={true} path='*' component={NotFound} />
                </Switch>
              </ScrollToTop>
            </BrowserRouter>
          </div>
        </Context.Provider>

      </div>
    );
  }
}

export default App;
