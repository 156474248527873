import React from 'react';
import Context from '../../assets/js/Context';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import PersonIcon from '@material-ui/icons/Person';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import AccountBoxIcon from '@material-ui/icons/AccountBox';
import Cookies from "universal-cookie";
import HomeIcon from '@material-ui/icons/Home';
import ShoppingCartIcon from '@material-ui/icons//ShoppingCart';
// import InputBase from '@material-ui/core/InputBase';
// import PersonIcon from '@material-ui/icons/Person';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { withStyles } from '@material-ui/styles';
import { withRouter } from 'react-router-dom';
const cookies = new Cookies();

const styles = theme => ({
  root: {
    direction: 'rtl',
    flexGrow: 1,
  },
  AppBar: {
    backgroundColor: '#ffa450',
  },
  Col: {
    display: 'flex',

    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: 20,
  },
  icons: {
    color: '#fff',
    marginRight: 10,
    cursor: 'pointer',
    '&:hover': {
      color: '#f8c429!important',
      transitionDuration: '.3s',
      transitionTimingFunction: 'linear',
    },
  },
  UsersHead: {
    width: '95%',
    height: 65,
    display: 'flex',
    marginRight: '2.5%',
    marginLeft: '2.5%',
    justifyContent: 'space-between',
    alignItems: 'center',
  },


})
class DenseAppBar extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      nameCate: '',
      image: [],
      spinerBtn: false,
      nameCity: '',
      nameItem: '',
      discountItem: '',
      descItem: '',
      menu: false,
    }
  }
 
  render() {

    const { classes } = this.props;
    return (
      <Context.Consumer>
        {ctx => {
          return (
            <div className={classes.root}>

              <AppBar position="static" className={classes.AppBar} style={this.state.menu ? { height: 140 } : null}>
                <Toolbar variant="dense" id='navbarCContiner'>

                  <Typography className={classes.title} variant="h6" noWrap>
                    <img src={require('../../assets/img/logo.png')} alt='logo' style={{ height: 53 }} />
                  </Typography>
                  {cookies.get("token") ? (
                    <div style={{ display: 'flex' }}>
                      <div id='navContentLeft'>
                        <div id='navbarBtnContiner' style={{ display: 'flex' }} >
                          <Link to='/'>
                            <HomeIcon />
                            <div id='NAMENAV' style={{ marginRight: 5 }}>الرئيسية</div>
                          </Link>
                        </div>
                        <div id='navbarBtnContiner' style={{ display: 'flex' }}>
                          <Link to='/Account'>
                            <PersonIcon />
                            <div id='NAMENAV' style={{ marginRight: 5 }}>الحساب</div>
                          </Link>
                        </div>
                        <div id='navbarBtnContiner' style={{ display: 'flex' }}>
                          <Link to='/Shop' style={{ position: 'relative' }}>
                          <span id='DOTSR' style={ctx.value.Dots>=1?{display:'flex'}:{display:'none'}}>{ctx.value.Dots}</span>
                            <ShoppingCartIcon />
                           
                            <div id='NAMENAV' style={{ marginRight: 5 }}>السلة</div>
                          </Link>
                        </div>
                      </div>
                      <div style={{ height: 80, width: '100%', display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                        <div id='navContentLeftmenuTrue'>
                          <Link style={{ color: '#fff', margin: '0px 5px' }} to='/'>
                            <div id='roundedIcon'>
                              <HomeIcon />
                            </div>

                          </Link>
                          <Link style={{ color: '#fff', margin: '0px 5px' }} to='/Account'>
                            <div id='roundedIcon'>
                              <PersonIcon />
                            </div>

                          </Link>
                          <Link style={{ color: '#fff', margin: '0px 5px', position: 'relative' }} to='/Shop'>
                            <div id='DOTSR1' style={ctx.value.Dots>=1?{display:'flex'}:{display:'none'}}>{ctx.value.Dots}</div>
                            <div id='roundedIcon'>
                              <ShoppingCartIcon />
                            </div>
                          </Link>
                        </div>
                      </div>
                    </div>)
                    : (
                      <div style={{ display: 'flex' }}>
                        <div id='navContentLeft'>
                          <div id='navbarBtnContiner' style={{ display: 'flex' }} >
                            <Link to='/'>
                              <HomeIcon />
                              <div style={{ marginRight: 5 }}>الرئيسية</div>
                            </Link>
                          </div>
                          <div id='navbarBtnContiner' style={{ display: 'flex' }}>
                            <Link to='/login'>
                              <AccountBoxIcon />
                              <div style={{ marginRight: 5 }}>تسجيل الدخول</div>
                            </Link>
                          </div>
                          <div id='navbarBtnContiner' style={{ display: 'flex' }}>
                            <Link to='/signup'>
                              <PersonAddIcon />
                              <div style={{ marginRight: 5 }}>انشاء حساب</div>
                            </Link>
                          </div>
                        </div>
                        <div style={{ height: 80, width: '100%', display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                          <div id='navContentLeftmenuTrue'>
                            <Link style={{ color: '#fff', margin: '0px 5px' }} to='/'>
                            <div id='roundedIcon'>
                              <HomeIcon />
                            </div>
                            </Link>
                            <Link style={{ color: '#fff', margin: '0px 5px' }} to='/login'>
                            <div id='roundedIcon'>
                            <AccountBoxIcon />
                            </div>
                              
                            </Link>
                            <Link style={{ color: '#fff', margin: '0px 5px' }} to='/signup'>
                            <div id='roundedIcon'>
                            <PersonAddIcon />
                            </div>
                              
                            </Link>
                          </div>
                        </div>
                      </div>
                    )}

                </Toolbar>

              </AppBar>
            </div>
          );
        }}
      </Context.Consumer>
    )
  }

}
DenseAppBar.propTypes = {
  classes: PropTypes.object.isRequired,
}
export default withRouter(withStyles(styles)(DenseAppBar))


