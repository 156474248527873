import React, { Component } from "react";
import { Carousel } from 'react-bootstrap';
import Cookies from "universal-cookie";
import host from '../../assets/js/Host';
import axios from 'axios';
const cookies = new Cookies();

class Slider extends Component {
  constructor(props) {
    super(props);
    this.state = {
      banner: [],
    }
  }
  componentDidMount() {
    var header = { "Content-Type": "application/x-www-form-urlencoded", token: cookies.get("token") };
    axios.get(host + `dashbord/banners/`, { headers: header })
      .then(res => {
        // console.log(res.data.data.banners);
        this.setState({ banner: res.data.data.banners, spin: true });
      })
      .catch(error => { console.log(error) 
        this.setState({spin: true})})
  }
  render() {

    return (
      <div id='BannerContiner'>
        <Carousel>
          {this.props.page === 'itemDesc' ? (
            this.props.photo.map(pho =>
              <Carousel.Item key={pho.id}>
                <img src={host + 'files/' + pho.photo} style={{ width: '100%', height: '25vw' }} alt='img' />
              </Carousel.Item>
            )
          ) : (
              this.state.banner.map(bann =>
                <Carousel.Item key={bann.id}>
                  <img src={host + 'files/' + bann.photo} style={{ width: '100%', height: '25vw' }} alt='img' />
                </Carousel.Item>
              )
            )}
        </Carousel>

      </div>
    );
  }
}
export default Slider;