import React from 'react';
import Component from "@reactions/component";
import { Pane, Dialog, Spinner } from 'evergreen-ui';
import CancelIcon from '@material-ui/icons/Cancel';
import PrintProvider, { Print, NoPrint } from 'react-easy-print';
import Prints from '@material-ui/icons/Print';
import { ToastContainer, toast } from 'react-toastify';
import Cookies from "universal-cookie";
import { Tabs, Tab } from 'react-bootstrap';
import Paper from '@material-ui/core/Paper';
import host from '../../assets/js/Host';
import axios from 'axios';
import Lottie from 'lottie-react-web'
import qs from 'qs';
import * as moment from "moment";
import animation from '../../assets/json/tk.json';
import MaterialDatatable from "material-datatable";
import { createTheme, MuiThemeProvider } from '@material-ui/core/styles';
const columnsW = [
    { field: "done", name: "طباعه ", options: { width: 100, filter: true, sort: true, } },
    { field: "cancel", name: "رفض ", options: { width: 100, filter: true, sort: true, } },
    { field: "date", name: "تاريخ ", options: { width: 200, filter: true, sort: true, } },
    { field: "address", name: "العنوان", options: { filter: true, sort: true, } },
    { field: "phone", name: "رقم الهاتف", options: { filter: true, sort: true, } },
    { field: "name", name: "الاسم", options: { filter: true, sort: true, } },
];
const columnsD = [
    { field: "print", name: "طباعه ", options: { width: 100, filter: true, sort: true, } },
    { field: "date", name: "تاريخ ", options: { width: 200, filter: true, sort: true, } },
    { field: "address", name: "العنوان", options: { filter: true, sort: true, } },
    { field: "phone", name: "رقم الهاتف", options: { filter: true, sort: true, } },
    { field: "name", name: "الاسم", options: { filter: true, sort: true, } },
];
const columnsC = [
    { field: "print", name: "طباعه ", options: { width: 100, filter: true, sort: true, } },
    { field: "date", name: "تاريخ ", options: { width: 200, filter: true, sort: true, } },
    { field: "address", name: "العنوان", options: { filter: true, sort: true, } },
    { field: "phone", name: "رقم الهاتف", options: { filter: true, sort: true, } },
    { field: "name", name: "الاسم", options: { filter: true, sort: true, } },
];
const options = {
    selectableRows: false,
    responsive: 'scroll',
    rowCursorHand: false,
    sort: false,
    print: false,
    filter: false,
    download: false,
    textLabels: {
        body: {
            noMatch: " لم يتم العثور على سجلات مطابقة",
            toolTip: "فرز",
        },
        pagination: {
            next: "الصفحة التالية",
            previous: "الصفحة السابقة",
            rowsPerPage: "عدد الصفوف",
            displayRows: "من",
        },
        toolbar: {
            search: "بحث",
            downloadCsv: "تنزيل",
            print: "Print",
            viewColumns: " التحكم بالاعمدة",
            filterTable: "فلتر",
        },
        filter: {
            all: "الكل",
            title: "فلتر",
            reset: "إعادة تعيين",
        },
        viewColumns: {
            title: "عرض الأعمدة",
            titleAria: "إظهار / إخفاء أعمدة الجدول",
        },

    }
}
const cookies = new Cookies();

class Category extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            Watting: [],
            Done: [],
            Cancel: [],
            spin: false,

        }
    }
    getMuiTheme = () => createTheme({
        overrides: {
            MaterialDatatableBodyCell: { root: { textAlign: 'right', } },
            MuiTableCell: { root: { textAlign: 'right' } },
        }
    })

    componentDidMount() {
        var header = { "Content-Type": "application/x-www-form-urlencoded", token: cookies.get("AdminToken") };
        axios.get(host + `dashbord/orders/all?status=0`, { headers: header })
            .then(res => {
                // console.log(res.data.data);
                let arr = [];
                for (let i = 0; i < res.data.data.orders.length; i++) {
                    // console.log(res.data.data.users);
                    let obj = {
                        name: res.data.data.orders[i].name,
                        phone: res.data.data.orders[i].phone,
                        address: res.data.data.orders[i].government + '/' + res.data.data.orders[i].city,
                        date: moment(res.data.data.orders[i].createdAt).format('DD/MM/YYYY LT'),
                        cancel: <Component initialState={{ isShown: false }}>
                            {({ state, setState }) => (
                                <Pane>
                                    <Dialog
                                        isShown={state.isShown}
                                        title="رفض الطلب"
                                        intent="danger"
                                        onCloseComplete={() => setState({ isShown: false })}
                                        confirmLabel="تاكيد"
                                        cancelLabel="الغاء"
                                        onConfirm={() => {
                                            this.cahngeStatus(res.data.data.orders[i].id, 2)
                                            setState({ isShown: false })
                                        }}
                                    >
                                        هل انت متاكد من عملية رفض الطلب
                                    </Dialog>
                                    <CancelIcon style={{ color: '#d26a74', cursor: 'pointer' }} onClick={() => setState({ isShown: true })} />
                                </Pane>
                            )}
                        </Component>,
                        done: <Component initialState={{ isShown: false, data: [], info: '',spin:true }}>
                            {({ state, setState }) => (
                                <Pane>
                                    <Dialog
                                        isShown={state.isShown}
                                        title="طباعة"
                                        onCloseComplete={() => setState({ isShown: false })}
                                        confirmLabel="طباعة"
                                        cancelLabel="الغاء"
                                        onConfirm={() => {
                                            window.print();
                                            this.cahngeStatus(res.data.data.orders[i].id, 1)
                                            setState({ isShown: false })
                                        }}
                                    >
                                        <PrintProvider>

                                            <NoPrint>
                                                {!state.spin ? (
                                                    <Print single name="foo">
                                                        {state.data.map((d, i) => (
                                                            <div key={i} id='tablePrintMargin' >
                                                                <div id='RootNAmeprint'>
                                                                    <div> {moment(state.info.createdAt).format('DD/MM/YYYY LT')}</div>
                                                                    <div> {state.info.id}</div>
                                                                </div>
                                                                <div id='RootNAmeprint'>
                                                                    <div> {state.info.phone}</div>
                                                                    <div> {state.info.name}</div>
                                                                </div>
                                                                <div id='RootNAmeprint' style={{ justifyContent: 'center' }}>
                                                                    <div> {state.info.government + ' / ' + state.info.city}</div>
                                                                </div>
                                                                <div id='SectionTable'>{d[0].items.category.name}</div>
                                                                <table id='students' key={d.id}>
                                                                    <thead >
                                                                        <tr >
                                                                            <th style={{ fontSize: '1.4rem' }}>الرقم</th>
                                                                            <th style={{ fontSize: '1.4rem' }}>الصنف</th>
                                                                            <th style={{ fontSize: '1.4rem' }}>العدد/الوزن</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody >
                                                                        {d.map((item, ii) => (
                                                                            <tr key={ii} >
                                                                                <td style={{ width: 60, fontSize: '1.4rem' }} >{ii + 1}</td>
                                                                                <td style={{ fontSize: '1.4rem' }}>{item.items.name}</td>
                                                                                <td style={{ width: 100, fontSize: '1.4rem' }} >{item.count} {item.items.type === 0 ? 'قطعه' : 'كغ'}</td>
                                                                            </tr>

                                                                        ))}
                                                                    </tbody>

                                                                </table>
                                                                <div id='notsAllOrder'>الملاحضات:{state.info.notes}</div>
                                                                <div id='notsAllOrder'>وقت التسليم : بعد {state.info.time}</div>
                                                            </div>
                                                        ))}
                                                    </Print>
                                                ) : (
                                                    <div style={{ width: '100%', height: 300, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                        <Spinner />
                                                    </div>
                                                )}
                                            </NoPrint>

                                        </PrintProvider>
                                    </Dialog>
                                    <Prints
                                        onClick={() => {
                                            setState({ isShown: true })
                                            axios.get(host + `orders/order/${res.data.data.orders[i].id}`, { headers: { "Content-Type": "application/json", token: cookies.get("token") } })
                                                .then(response => {
                                                    console.log(response.data.data.order);
                                                    setState({ data: response.data.data.order.orderItems, info: response.data.data.order,spin:false })
                                                   
                                                })
                                                .catch((error) => { console.log('error ' + error) })


                                        }} style={{ color: '#ffc107', cursor: 'pointer' }} />
                                </Pane>
                            )}
                        </Component>
                    };
                    arr.push(obj);
                }
                this.setState({
                    Watting: arr, spin: true
                });
            })
            .catch(error => {
                console.log(error)
                this.setState({ spin: true })
            })
        ////////////////////////// done////////////////////////////

        axios.get(host + `dashbord/orders/all?status=1`, { headers: header })
            .then(res => {
                // console.log(res.data.data.orders);
                let arr = [];
                for (let i = 0; i < res.data.data.orders.length; i++) {
                    // console.log(res.data.data.users);
                    let obj = {
                        name: res.data.data.orders[i].name,
                        phone: res.data.data.orders[i].phone,
                        address: res.data.data.orders[i].government + '/' + res.data.data.orders[i].city,
                        date: moment(res.data.data.orders[i].createdAt).format('DD/MM/YYYY LT'),

                        print: <Component initialState={{ isShown: false, data: [], info: '',spin:true }}>
                            {({ state, setState }) => (
                                <Pane>
                                    <Dialog
                                        isShown={state.isShown}
                                        title="طباعة"
                                        onCloseComplete={() => setState({ isShown: false })}
                                        confirmLabel="طباعة"
                                        cancelLabel="الغاء"
                                        onConfirm={() => {
                                            window.print();

                                            setState({ isShown: false })
                                        }}
                                    >
                                        <PrintProvider>

                                            <NoPrint>
                                            {!state.spin ? (
                                                <Print single name="foo">
                                                    {state.data.map((d, i) => (
                                                        <div key={i} id='tablePrintMargin' >
                                                            <div id='RootNAmeprint'>
                                                                <div> {moment(state.info.createdAt).format('DD/MM/YYYY LT')}</div>
                                                                <div> {state.info.id}</div>
                                                            </div>
                                                            <div id='RootNAmeprint'>
                                                                <div> {state.info.phone}</div>
                                                                <div> {state.info.name}</div>
                                                            </div>
                                                            <div id='RootNAmeprint' style={{ justifyContent: 'center' }}>
                                                                <div> {state.info.government + ' / ' + state.info.city}</div>
                                                            </div>
                                                            <div id='SectionTable'>{d[0].items.category.name}</div>
                                                            <table id='students' key={d.id}>
                                                                <thead>
                                                                    <tr>
                                                                        <th style={{ fontSize: '1.4rem' }}>الرقم</th>
                                                                        <th style={{ fontSize: '1.4rem' }}>الصنف</th>
                                                                        <th style={{ fontSize: '1.4rem' }}>العدد/الوزن</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody >
                                                                    {d.map((item, ii) => (
                                                                        <tr key={ii}>
                                                                            <td style={{ width: 60, fontSize: '1.4rem' }} >{ii + 1}</td>
                                                                            <td style={{ fontSize: '1.4rem' }}>{item.items.name}</td>
                                                                            <td style={{ width: 100, fontSize: '1.4rem' }} >{item.count} {item.items.type === 0 ? 'قطعه' : 'كغ'}</td>
                                                                        </tr>

                                                                    ))}
                                                                </tbody>

                                                            </table>
                                                            <div id='notsAllOrder'>الملاحضات:{state.info.notes}</div>
                                                            <div id='notsAllOrder'>وقت التسليم : بعد {state.info.time}</div>
                                                        </div>
                                                    ))}
                                                </Print>
                                                  ) : (
                                                    <div style={{ width: '100%', height: 300, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                        <Spinner />
                                                    </div>
                                                )}
                                            </NoPrint>
                                        </PrintProvider>
                                    </Dialog>
                                    <Prints
                                        onClick={() => {
                                            setState({ isShown: true })
                                            axios.get(host + `orders/order/${res.data.data.orders[i].id}`, { headers: { "Content-Type": "application/json", token: cookies.get("token") } })
                                                .then(response => {
                                                    // console.log(response.data.data.order);
                                                    setState({ data: response.data.data.order.orderItems, info: response.data.data.order ,spin:false})
                                                   
                                                })
                                                .catch((error) => { console.log('error ' + error) })


                                        }} style={{ color: '#ffc107', cursor: 'pointer' }} />
                                </Pane>
                            )}
                        </Component>
                    };
                    arr.push(obj);
                }
                this.setState({
                    Done: arr, spin: true
                });
            })
            .catch(error => {
                console.log(error)
                this.setState({ spin: true })
            })
        // ///////////////////////////////// cancel /////////////////////////////
        axios.get(host + `dashbord/orders/all?status=2`, { headers: header })
            .then(res => {
                // console.log(res.data.data.orders);
                let arr = [];
                for (let i = 0; i < res.data.data.orders.length; i++) {
                    // console.log(res.data.data.users);
                    let obj = {
                        name: res.data.data.orders[i].name,
                        phone: res.data.data.orders[i].phone,
                        address: res.data.data.orders[i].government + '/' + res.data.data.orders[i].city,
                        date: moment(res.data.data.orders[i].createdAt).format('DD/MM/YYYY LT'),
                        print: <Component initialState={{ isShown: false, data: [], info: '',spin:true }}>
                            {({ state, setState }) => (
                                <Pane>
                                    <Dialog
                                        isShown={state.isShown}
                                        title="طباعة"
                                        onCloseComplete={() => setState({ isShown: false })}
                                        confirmLabel="طباعة"
                                        cancelLabel="الغاء"
                                        onConfirm={() => {
                                            window.print();

                                            setState({ isShown: false })
                                        }}
                                    >
                                        <PrintProvider>

                                            <NoPrint>
                                            {!state.spin ? (
                                                <Print single name="foo">
                                                    {state.data.map((d, i) => (
                                                        <div key={i} id='tablePrintMargin' >
                                                            <div id='RootNAmeprint'>
                                                                <div> {moment(state.info.createdAt).format('DD/MM/YYYY LT')}</div>
                                                                <div> {state.info.id}</div>
                                                            </div>
                                                            <div id='RootNAmeprint'>
                                                                <div> {state.info.phone}</div>
                                                                <div> {state.info.name}</div>
                                                            </div>
                                                            <div id='RootNAmeprint' style={{ justifyContent: 'center' }}>
                                                                <div> {state.info.government + ' / ' + state.info.city}</div>
                                                            </div>
                                                            <div id='SectionTable'>{d[0].items.category.name}</div>
                                                            <table id='students' key={d.id}>
                                                                <thead>
                                                                    <tr>
                                                                        <th style={{ fontSize: '1.4rem' }}>الرقم</th>
                                                                        <th style={{ fontSize: '1.4rem' }}>الصنف</th>
                                                                        <th style={{ fontSize: '1.4rem' }}>العدد/الوزن</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody >
                                                                    {d.map((item, ii) => (
                                                                        <tr key={ii}>
                                                                            <td style={{ width: 60, fontSize: '1.4rem' }} >{ii + 1}</td>
                                                                            <td style={{ fontSize: '1.4rem' }}>{item.items.name}</td>
                                                                            <td style={{ width: 100, fontSize: '1.4rem' }} >{item.count} {item.items.type === 0 ? 'قطعه' : 'كغ'}</td>
                                                                        </tr>

                                                                    ))}
                                                                </tbody>

                                                            </table>
                                                            <div id='notsAllOrder'>الملاحضات:{state.info.notes}</div>
                                                            <div id='notsAllOrder'>وقت التسليم : بعد {state.info.time}</div>
                                                        </div>
                                                    ))}
                                                </Print>
                                                ) : (
                                                    <div style={{ width: '100%', height: 300, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                        <Spinner />
                                                    </div>
                                                )} 
                                            </NoPrint>
                                        </PrintProvider>
                                    </Dialog>
                                    <Prints
                                        onClick={() => {
                                            setState({ isShown: true })
                                            axios.get(host + `orders/order/${res.data.data.orders[i].id}`, { headers: { "Content-Type": "application/json", token: cookies.get("token") } })
                                                .then(response => {
                                                    // console.log(response.data.data.order);
                                                    setState({ data: response.data.data.order.orderItems, info: response.data.data.order,spin:false })
                                                    
                                                })
                                                .catch((error) => { console.log('error ' + error) })


                                        }} style={{ color: '#ffc107', cursor: 'pointer' }} />
                                </Pane>
                            )}
                        </Component>

                    };
                    arr.push(obj);
                }
                this.setState({
                    Cancel: arr, spin: true
                });
            })
            .catch(error => {
                console.log(error)
                this.setState({ spin: true })
            })
    }
    cahngeStatus(id, value) {
        axios.post(host + `dashbord/order/status/change/${id}`,
            qs.stringify({ status: value }), {
            headers: { "Content-Type": "application/x-www-form-urlencoded", Authorization: cookies.get("token") }
        }).then(response => {
            this.setState({ nameCate: '', BtnAdd: false })
            toast('تم تغيير حالة الطلب بنجاح', {
                position: "bottom-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true
            });
            this.componentDidMount()
        }).catch(error => { console.log(error) })
    }
    render() {
        if (this.state.spin) {
            return (
                <div>

                    <Paper className={'classesroot'} >
                        <Tabs defaultActiveKey={this.state.activetap} id="uncontrolled-tab-example" style={{ backgroundColor: '#fff', }}>
                            <Tab eventKey='wating' title="طلبات قيد الانتظار" tabClassName='wating' onClick={() => this.setState({ activetap: 'wating' })}>
                                <div className='DataTableContiner'>
                                    <MuiThemeProvider
                                        theme={this.getMuiTheme()}>
                                        <MaterialDatatable data={this.state.Watting} columns={columnsW} options={options} />
                                    </MuiThemeProvider>
                                </div>
                            </Tab>
                            <Tab eventKey="done" title="طلبات تم انجازها " tabClassName='done' onClick={() => this.setState({ activetap: 'done' })}>
                                <div className='DataTableContiner'>
                                    <MuiThemeProvider
                                        theme={this.getMuiTheme()}>
                                        <MaterialDatatable data={this.state.Done} columns={columnsD} options={options} />
                                    </MuiThemeProvider>
                                </div>
                            </Tab>
                            <Tab eventKey="cancel" title="طلبات تم الغاؤها " tabClassName='cancel' onClick={() => this.setState({ activetap: 'cancel' })}>
                                <div className='DataTableContiner'>
                                    <MuiThemeProvider
                                        theme={this.getMuiTheme()}>
                                        <MaterialDatatable data={this.state.Cancel} columns={columnsC} options={options} />
                                    </MuiThemeProvider>
                                </div>
                            </Tab>
                        </Tabs>
                    </Paper>
                    <ToastContainer
                        position="bottom-left"
                        autoClose={10000}
                        hideProgressBar={false}
                        newestOnTop={false}
                        closeOnClick
                        rtl={false}
                        pauseOnVisibilityChange
                        draggable
                        pauseOnHover
                    />
                </div>
            )
        }
        else {
            return (
                <div style={{ width: '100%', height: 'calc(100vh - 60px)', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <Lottie style={{ width: 300 }}
                        options={{
                            animationData: animation
                        }}
                    />
                </div>
            )
        }
    }
}
export default Category