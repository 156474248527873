import React from 'react';
import Avatar from '@material-ui/core/Avatar';
import CssBaseline from '@material-ui/core/CssBaseline';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import { Icon } from 'evergreen-ui';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { Link } from 'react-router-dom'
import 'react-phone-input-2/lib/style.css'
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/styles';
import host from '../../assets/js/Host';
import axios from 'axios';
import { withRouter } from 'react-router-dom';
import imagg from '../../assets/img/logo.png'
import { ToastContainer, toast } from 'react-toastify';
import qs from 'qs';
import Cookies from "universal-cookie";
const cookies = new Cookies();
const styles = theme => ({
  root: {
    height: '100vh',
  },
  image: {
    backgroundColor: '#ffa450',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100vh',
    width: '100%',
  },
  paper: {
    height:'100vh',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent:'center',
  },
  avatar: {
    margin: 10,
    backgroundColor: '#ffa450',
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    height: '60Vh',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: 30,
  },
  submit: {
    marginTop: 50,
    width: 200,
  },
});

class SignInSide extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      passwordOld: '',
      password1: '',
      spinBtn: false,
    }
  }
  resendCode() {
    axios.put(host + `users/auth/password`,
      qs.stringify({
        password: this.state.passwordOld,
        new_password: this.state.password1,
      }), {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded", Authorization: cookies.get("token")
      }
    }).then(response => {
      // console.log(response.data.data);
      window.location.href = "/Account";
      toast('تم تغيير كلمه المرور', {
        position: "bottom-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true
      });

    }).catch(error => { 
      console.log(error) 
      toast('كلمه المرور غير صحيحه', {
        position: "bottom-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true
      });
    })


      ;
  }
  render() {
    const { classes } = this.props;
    return (
      <Grid container component="main" className={classes.root}>
        <CssBaseline />
        <Grid item xs={false} md={7} lg={7} className={classes.image} id='hideenlogo' >
          <img style={{width:'50%'}} src={imagg} alt='logo' />
        </Grid>
        <Grid item xs={12} md={5} lg={5} component={Paper} elevation={6} square>
        <div style={{ display: 'flex', justifyContent: 'flex-start', width: '100%', padding: 20 }}>
              <Link to='/Account'>
                <Icon icon="circle-arrow-left" size={30} color="#ffa450"
                  style={{ cursor: 'pointer' }} />
              </Link>
            </div>
          <div className={classes.paper}>
            
            <Avatar className={classes.avatar}>
              <LockOutlinedIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
              تغيير كلمة المرور
            </Typography>
            <div className={classes.form} noValidate>
              <div id='field1_ah'>
                <input autoComplete='off' type='password' placeholder='  كلمه المرور القديمه' id='InputTExtDash22' style={{ width: 260, textAlign: 'center', }}
                  onChange={(e) => this.setState({ passwordOld: e.target.value })} />
              </div>
              <div id='field1_ah'>
                <input autoComplete='off' type='password' placeholder=' كلمه المرور الجديده ' id='InputTExtDash22' style={{ width: 260, textAlign: 'center', }}
                  onChange={(e) => this.setState({ password1: e.target.value })} />
              </div>

              <div id='log1_ah'>
                <div id='log_ah' onClick={(e) => {
                  this.resendCode()
                }}>تغيير</div>
              </div>
            </div>
          </div>
        </Grid>
        <ToastContainer
          position="bottom-center"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnVisibilityChange
          draggable
          pauseOnHover
        />
      </Grid>
    );
  }
}
SignInSide.propTypes = {
  classes: PropTypes.object.isRequired,
}
export default withRouter(withStyles(styles)(SignInSide))