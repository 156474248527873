import React from 'react';
import Avatar from '@material-ui/core/Avatar';
import CssBaseline from '@material-ui/core/CssBaseline';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import { toaster, Spinner } from 'evergreen-ui';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/styles';
import Cookies from "universal-cookie";
import host from '../../../assets/js/Host';
import axios from 'axios';
import { withRouter } from 'react-router-dom';
import imagg from '../../../assets/img/logo.png'

const cookies = new Cookies();

const styles = theme => ({
  root: {
    height: '100vh',
  },
  image: {
    backgroundColor:'#ffa450',
    display:'flex',
    alignItems:'center',
    justifyContent: 'center',
    height:'100vh',
    width:'100%',
  },
  paper: {
    // margin: theme.spacing(8, 4),
    height:'100vh',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent:'center',
  },
  avatar: {
    margin: 10,
    backgroundColor: '#ffa450',
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: 30,
  },
  submit: {
    marginTop: 50,
    width: 200,
  },
});

class SignInSide extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      phone: '',
      password: '',
      spinBtn: false,
    }
  }
  signIN(e) {

    e.preventDefault()
    axios.post(host + `dashbord/login`, {
      phone: this.state.phone,
      password: this.state.password,
      player_id: localStorage.getItem('player_id')
    })
      .then(response => {
        cookies.set("AdminToken", response.data.data.token, { expires: new Date(Date.now() + 604800000) });
        window.location.href = "/CategoryDash";
        this.setState({ spinBtn: false })
      })
      .catch(error => {
        if (error.response) {
          this.setState({ spinBtn: false })
          toaster.danger("الرجاء التحقق من رقم الهاتف وكلمة المرور");
        }
      });
  }
  render() {
    const { classes } = this.props;
    return (
      <Grid container component="main" className={classes.root}>
        <CssBaseline />
        <Grid item xs={false}  md={7} lg={7} className={classes.image} id='hideenlogo' >
          <img style={{width:'50%'}} src={imagg} alt='logo' />
        </Grid>
        <Grid item xs={12}  md={5} lg={5} component={Paper} elevation={6} square>
          <div className={classes.paper}>
            <Avatar className={classes.avatar}>
              <LockOutlinedIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
               تسجيل الدخول الى لوحة التحكم
          </Typography>
            <div className={classes.form} noValidate>
            <div id='field1_ah'>
                <input autoComplete='off' type='text' placeholder=' رقم الهاتف ' id='InputTExtDash22' style={{ width: 260, textAlign:'center', paddingLeft: 20 }}
                  onChange={(e) => this.setState({ phone: e.target.value })} />
              </div>
              <div id='field1_ah'>
                <input autoComplete='off' type='password' placeholder=' كلمه المرور ' id='InputTExtDash22' style={{ width: 260, textAlign:'center', paddingLeft: 20 }}
                  onChange={(e) => this.setState({ password: e.target.value })} />
              </div>
              <div id='log1_ah'>
                {!this.state.spinBtn ? (
                  <div id='log_ah' onClick={(e) => {
                    this.setState({ spinBtn: true })
                    this.signIN(e)
                  }}>تسجيل الدخول</div>
                ) : (
                    <div id='log_ah' ><Spinner size={24} /></div>
                  )}
              </div>
            </div>
          </div>
        </Grid>
      </Grid>
    );
  }
}
SignInSide.propTypes = {
  classes: PropTypes.object.isRequired,
}
export default withRouter(withStyles(styles)(SignInSide))