import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import SpeedDial from '@material-ui/lab/SpeedDial';
import SpeedDialAction from '@material-ui/lab/SpeedDialAction';
import MoreVert from '@material-ui/icons/MoreVert';
const useStyles = makeStyles(theme => ({
  root: {
    width: 250,
    transform: 'translateZ(0px)',
    flexGrow: 1,
    marginRight:-10,
    marginTop:-10,
    position:'absolute',
  },
  BTN:{
width:56,
height:56,
backgroundColor:'#ffa450',
borderRadius:'50%',
position:'absolute',
display:'flex',
alignItems:'center',
justifyContent:'center',
marginLeft:50,
zIndex:3,
cursor:'pointer',
  },
}));


export default function OpenIconSpeedDial(props) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [hidden] = React.useState(false);


  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div className={classes.root}
    onMouseLeave={handleClose}>
        <div  
        onMouseEnter={handleOpen}
       
        className={classes.BTN}><MoreVert style={{color:'#fff'}}/></div>
      <SpeedDial 
        ariaLabel="SpeedDial openIcon example"
        className={classes.speedDial}
        hidden={hidden}
        icon={<MoreVert style={{color:'#2b3853'}}/>}
        onClose={handleClose}
        onOpen={handleOpen}
        open={open}
        direction={'right'}
        
      >
        {props.acti.map(action => (
          <SpeedDialAction 
            key={action.name}
            icon={action.icon}
            tooltipTitle={action.name}
            onClick={handleClose}
            
          />
        ))}
      </SpeedDial>
    </div>
  );
}
