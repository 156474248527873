import React from 'react';
import Context from '../../assets/js/Context';
import Cookies from "universal-cookie";
import host from '../../assets/js/Host';
import axios from 'axios';
import Lottie from 'lottie-react-web'
import animation from '../../assets/json/tk.json';
import Navbar from '../common/navbar';
import Slider from '../common/slider';
import { Link } from 'react-router-dom';
import {Row,Col} from 'react-bootstrap';
import Footer from '../common/footer';
import Disable from '../common/Disabled';
const cookies = new Cookies();

class Home extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            spin: false,
            categories: [],
        }
    }

    componentDidMount() {
        var header = { "Content-Type": "application/x-www-form-urlencoded", token: cookies.get("OfficeToken") };
        axios.get(host + `category/mains`, { headers: header })
            .then(res => {
                // console.log(res.data.data.categories);
                this.setState({ categories: res.data.data.categories, spin: true })
            })
            .catch(error => {
                console.log(error)
                this.setState({ spin: true })
            })


    }

    render() {
        if (this.state.spin) {
            return (
                <Context.Consumer>
                {ctx => {
                     if (ctx.value.isActive) {
                    return (
                <div style={{ backgroundColor: '#ecf0f1' }}>
                    <Navbar />
                    <Slider />

                        <div >
                            <Row style={{margin:0,width:'100%' ,direction:'rtl'}}>
                               
                                {this.state.categories.map(cate =>
                                 <Col id='COLHomE' xs={6} md={4} lg={3}  key={cate.id} >
                                    <div id='sliderCateImgContiner'>
                                        <Link to={`/ItemCate/${cate.id}`} onClick={()=>{
                                            localStorage.setItem('IMCAT',host + 'files/' + cate.photo)
                                            localStorage.setItem('NAMECAT',cate.name)
                                        }}>
                                            <div id='underLinkCate'>
                                                <img width={100} height={100} src={host + 'files/' + cate.photo} style={{ cursor: 'pointer' }} alt='cate' />
                                                <div>{cate.name}</div>
                                            </div>
                                        </Link>
                                    </div>
                                    </Col>
                                )}
                               
                            </Row>
                        
                        </div>
                    <Footer />
                </div>
                 )
                }
                else {
                    return (
                        <Disable />
                    )
                }
                }}
            </Context.Consumer>
            )
        }
        else {
            return (
                <div style={{ width: '100%', height: 'calc(100vh - 60px)', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <Lottie style={{ width: 300}}
                        options={{
                            animationData: animation
                        }}
                    />
                </div>
            )
        }
    }
}
export default Home