import React from 'react';
import Component from "@reactions/component";
import { Pane, Dialog, SideSheet, Position, Spinner, Switch } from 'evergreen-ui';
import OpenWithIcon from '@material-ui/icons/Lock';
import EditIcon from '@material-ui/icons/Edit';
import { ToastContainer, toast } from 'react-toastify';
import Cookies from "universal-cookie";
import host from '../../assets/js/Host';
import axios from 'axios';
import Lottie from 'lottie-react-web'
import animation from '../../assets/json/tk.json';
import MaterialDatatable from "material-datatable";
import { createTheme, MuiThemeProvider } from '@material-ui/core/styles';
import * as moment from "moment";
import qs from 'qs';
const cookies = new Cookies();
const columns = [

    { field: "enable", name: "تعطيل/تفعيل", options: { width: 100, filter: true, sort: false, } },
    { field: "edit", name: "تعديل", options: { width: 100, filter: true, sort: false, } },
    { field: "password", name: "كلمه المرور", options: { width: 100, filter: true, sort: false, } },
    { field: "agent", name: "اسم المندوب", options: { filter: true, sort: false, } },
    { field: "date", name: "تاريخ الاشتراك", options: { filter: true, sort: false, } },
    { field: "address", name: "العنوان", options: { filter: true, sort: false, } },
    { field: "phone", name: "رقم الهاتف", options: { filter: true, sort: false, } },
    { field: "name", name: "اسم المتجر", options: { filter: true, sort: true, } },
];
const options = {
    selectableRows: false,
    responsive: 'scroll',
    rowCursorHand: false,
    sort: false,
    filter: false,
    print: false,
    download: false,
    rowsPerPage: 100,
    textLabels: {
        body: { noMatch: " لم يتم العثور على سجلات مطابقة", toolTip: "فرز", },
        pagination: { next: "الصفحة التالية", previous: "الصفحة السابقة", rowsPerPage: "عدد الصفوف", displayRows: "من", },
        toolbar: { search: "بحث", downloadCsv: "تنزيل", print: "Print", viewColumns: " التحكم بالاعمدة", filterTable: "فلتر", },
        filter: { all: "الكل", title: "فلتر", reset: "إعادة تعيين", },
        viewColumns: { title: "عرض الأعمدة", titleAria: "إظهار / إخفاء أعمدة الجدول", },
    }
}
class Category extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            office: [],
            cate: [],
            spin: false,
            nameCate: '',
            cateId: '',
            image: [],
        }
    }

    componentDidMount() {
        var header = { "Content-Type": "application/x-www-form-urlencoded", token: cookies.get("AdminToken") };

        axios.get(host + `dashbord/users/all`, { headers: header })
            .then(res => {
                console.log(res.data.data.users);

                let arr = [];
                for (let i = 0; i < res.data.data.users.length; i++) {
                    let obj = {
                        name: res.data.data.users[i].name,
                        phone: res.data.data.users[i].phone,
                        date: moment(res.data.data.users[i].createdAt).format('DD/MM/YYYY'),
                        address: res.data.data.users[i].government + ' / ' + res.data.data.users[i].city,
                        agent: res.data.data.users[i].agent,
                        password: <Component initialState={{ isShown: false, password: '' }}>
                            {({ state, setState }) => (
                                <Pane>
                                    <Dialog
                                        isShown={state.isShown}
                                        title='تغيير كلمه المرور'
                                        onCloseComplete={() => setState({ isShown: false })}
                                        confirmLabel="تغيير"
                                        cancelLabel="الغاء"
                                        onConfirm={() => {
                                            setState({ isShown: false })
                                            axios.put(host + `dashbord/users/${res.data.data.users[i].id}`,
                                                qs.stringify({ password: state.password, }), {
                                                headers: {
                                                    "Content-Type": "application/x-www-form-urlencoded", Authorization: cookies.get("token")
                                                }
                                            }).then(response => {
                                                this.componentDidMount()
                                                toast("تم تغيير كلمه المرور", {
                                                    position: "bottom-center",
                                                    autoClose: 5000,
                                                    hideProgressBar: false,
                                                    closeOnClick: true,
                                                    pauseOnHover: true,
                                                    draggable: true
                                                })

                                            }).catch(error => { console.log(error) })
                                        }}
                                    >
                                        <div style={{ display: 'flex', direction: 'rtl', height: 200 }}>
                                            <div id='field1_ah'>
                                                <input autoComplete='off' type='password' placeholder=' كلمه المرور الجديده ' id='InputTExtDash22' style={{ width: 260, textAlign: 'center', }}
                                                    onChange={(e) => setState({ password: e.target.value })} />
                                            </div>
                                        </div>
                                    </Dialog>
                                    <OpenWithIcon style={{ cursor: 'pointer', color: '#5ad25a' }} onClick={() => { setState({ isShown: true }) }} />
                                </Pane>
                            )}
                        </Component>,
                        edit: <Component initialState={{
                            isShown: false, spin: true, name: '', phone: '',
                            government: '', city: '',agent:''
                        }}>
                            {({ state, setState }) => (
                                <React.Fragment>
                                    <SideSheet
                                        position={Position.LEFT}
                                        isShown={state.isShown}
                                        onCloseComplete={() => setState({ isShown: false })}
                                    >
                                        <Pane margin={40}>

                                            <React.Fragment  >
                                                {!state.spin ? (
                                                    <div>
                                                        <div id='titleAddAppBar'>تعديل المعلومات</div>
                                                        <div id='inputAddContiner'>
                                                            <div id='field1_ah'>
                                                                <input autoComplete='off' type='text' placeholder=' اسم المتجر' id='InputTExtDash22' style={{ width: 260, textAlign: 'center', }}
                                                                    value={state.name} onChange={(e) => setState({ name: e.target.value })} />
                                                            </div>
                                                            <div id='field1_ah'>
                                                                <input autoComplete='off' type='text' placeholder=' رقم الهاتف ' id='InputTExtDash22' style={{ width: 260, textAlign: 'center', }}
                                                                    value={state.phone} onChange={(e) => setState({ phone: e.target.value })} />
                                                            </div>
                                                            <div id='field1_ah'>
                                                                <input autoComplete='off' type='text' placeholder=' المحافظه ' id='InputTExtDash22' style={{ width: 260, textAlign: 'center', }}
                                                                    value={state.government} onChange={(e) => setState({ government: e.target.value })} />
                                                            </div>
                                                            <div id='field1_ah'>
                                                                <input autoComplete='off' type='text' placeholder=' المنطقه ' id='InputTExtDash22' style={{ width: 260, textAlign: 'center', }}
                                                                    value={state.city} onChange={(e) => setState({ city: e.target.value })} />
                                                            </div>
                                                            <div id='field1_ah'>
                                                                <input autoComplete='off' type='text' placeholder=' اسم المندوب ' id='InputTExtDash22' style={{ width: 260, textAlign: 'center', }}
                                                                    value={state.agent} onChange={(e) => setState({ agent: e.target.value })} />
                                                            </div>
                                                        </div>
                                                        <div id='btnAddItemContiner'>
                                                            {!state.spinerBtn ? (
                                                                <div id='btnAddItem' onClick={() => {
                                                                    setState({ spinerBtn: true })
                                                                    axios.put(host + `dashbord/users/edit/${res.data.data.users[i].id}`,
                                                                        qs.stringify({
                                                                            name: state.name,
                                                                            phone: state.phone,
                                                                            government: state.government,
                                                                            city: state.city,
                                                                            agent: state.agent,
                                                                        }), {
                                                                        headers: {
                                                                            "Content-Type": "application/x-www-form-urlencoded", token: cookies.get("AdminToken")
                                                                        }
                                                                    }).then(response => {
                                                                        // console.log(response.data.data);
                                                                        setState({ isShown: false })
                                                                        this.componentDidMount()


                                                                    }).catch(error => {

                                                                        console.log(error)
                                                                    })
                                                                }}>تعديل</div>

                                                            ) : (
                                                                <div id='btnAddItem'><Spinner size={24} /></div>

                                                            )}
                                                        </div>

                                                    </div>
                                                ) : (
                                                    <div style={{ width: '100%', height: 400, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                        <Spinner />
                                                    </div>

                                                )}
                                            </React.Fragment  >
                                        </Pane>
                                    </SideSheet>


                                    <EditIcon style={{ cursor: 'pointer', color: '#338eff' }} onClick={() => {
                                        axios.get(host + `dashbord/users/user/${res.data.data.users[i].id}/`, { headers: header })
                                            .then(res => {
                                                console.log(res.data.data.users);
                                                setState({
                                                    name: res.data.data.users.name,
                                                    phone: res.data.data.users.phone,
                                                    government: res.data.data.users.government,
                                                    city: res.data.data.users.city,
                                                    agent: res.data.data.users.agent,
                                                    spin: false,
                                                });

                                            })
                                            .catch(error => {
                                                console.log(error)
                                                this.setState({ spin: true })
                                            })
                                        setState({ isShown: true })
                                    }} />
                                </React.Fragment>
                            )}
                        </Component>,
                        enable:
                            <Component initialState={{ isActive: res.data.data.users[i].isActive }}>
                                {({ state, setState }) => (

                                    <Switch style={{ direction: 'ltr' }}
                                        checked={state.isActive}
                                        onChange={e => {
                                            if (state.isActive) {
                                                setState({ isActive: false })
                                                this.enable(res.data.data.users[i].id)
                                            }
                                            else {
                                                setState({ isActive: true })
                                                this.enable(res.data.data.users[i].id)
                                            }
                                        }} />
                                )}
                            </Component>
                    };

                    arr.push(obj);
                }
                this.setState({
                    office: arr.reverse(), spin: true
                });

            })
            .catch(error => {
                console.log(error)
                this.setState({ spin: true })
            })
    }

    enable(id) {
        axios.post(host + `dashbord/users/enable/${id}`,
            qs.stringify({
            }), {
            headers: { "Content-Type": "application/x-www-form-urlencoded", token: cookies.get("AdminToken") }
        }).then(response => {
            this.componentDidMount()
            toast("تم التعديل بنجاح", {
                position: "bottom-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true
            });
        }).catch(error => {
            this.setState({ spinnerBtn: false })
            console.log(error.response.data)
        })
    }
    getMuiTheme = () => createTheme({
        overrides: {
            MaterialDatatableBodyCell: {
                root: { textAlign: 'right', },
            },
            MuiTableCell: {
                root: { textAlign: 'right', },
                // head: {  backgroundColor: "#FF0000", }
            },
        }
    })

    DeleteOffice(id) {
        var header = { "Content-Type": "application/x-www-form-urlencoded", token: cookies.get("AdminToken") };
        axios.delete(host + `items/item/${id}`, { headers: header })
            .then(res => {
                toast('تم الحذف بنجاح', {
                    position: "bottom-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true
                });
                this.componentDidMount();
            })
            .catch(error => { console.log(error) })

    }

    render() {
        if (this.state.spin) {
            return (
                <div>
                    <div style={{ direction: 'ltr' }}>

                        <MuiThemeProvider
                            theme={this.getMuiTheme()}>
                            <MaterialDatatable data={this.state.office} columns={columns} options={options} />
                        </MuiThemeProvider>
                    </div>
                    <ToastContainer
                        position="bottom-center"
                        autoClose={5000}
                        hideProgressBar={false}
                        newestOnTop={false}
                        closeOnClick
                        rtl={false}
                        pauseOnVisibilityChange
                        draggable
                        pauseOnHover
                    />
                </div>
            )
        }
        else {
            return (
                <div style={{ width: '100%', height: 'calc(100vh - 60px)', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <Lottie style={{ width: 300 }}
                        options={{
                            animationData: animation
                        }}
                    />
                </div>
            )
        }
    }
}
export default Category