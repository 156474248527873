import React from 'react';
import AppBar from '../common/appBar';
import Component from "@reactions/component";
import { Dialog, Pane } from 'evergreen-ui';
import DeleteIcon from '@material-ui/icons/Delete';
import Cookies from "universal-cookie";
import host from '../../assets/js/Host';
import axios from 'axios';
import Lottie from 'lottie-react-web'
import animation from '../../assets/json/tk.json';
import { Row, Col } from 'react-bootstrap';
import { ToastContainer, toast } from 'react-toastify';

const cookies = new Cookies();

class Category extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            banner: [],
            spin: false,

        }
    }
    componentDidMount() {
        var header = { "Content-Type": "application/x-www-form-urlencoded", token: cookies.get("AdminToken") };
        axios.get(host + `dashbord/banners/`, { headers: header })
            .then(res => {
                // console.log(res.data.data.banners);
                this.setState({ banner: res.data.data.banners, spin: true });
            })
            .catch(error => { console.log(error)
                this.setState({spin: true}) })
    }
    deletBanner(id) {
        var header = { "Content-Type": "application/x-www-form-urlencoded", token: cookies.get("AdminToken") };
        axios.delete(host + `dashbord/banners/${id}`, { headers: header })
            .then(response => {
                toast('تم الحذف بنجاح', {
                    position: "bottom-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true
                });
                this.componentDidMount();
            })
            .catch(error => { console.log(error) })
    }
    render() {
        if (this.state.spin) {
            return (
                <div>
                    <AppBar page='bannerDash' />
                    <Row style={{ margin: 0, padding: 0, direction: 'rtl' }}>
                        {this.state.banner.map(bann=>
                        <Col key={bann.id} id='colbanner' md={12} lg={6}>
                            <div id='BannerContinerDash'>
                                <img src={host + 'files/' + bann.photo} alt='banner' id='imgBannerDash' />
                                <Component initialState={{ isShown: false }}>
                                    {({ state, setState }) => (
                                        <Pane>
                                            <Dialog
                                                isShown={state.isShown}
                                                title="حذف الاعلان "
                                                intent="danger"
                                                onCloseComplete={() => setState({ isShown: false })}
                                                confirmLabel="حذف"
                                                cancelLabel="الغاء"
                                                onConfirm={() => {
                                                    setState({ isShown: false })
                                                    this.deletBanner(bann.id)
                                                }}
                                            >
                                                <span id='msgDelete'> هل انت متأكد من عملية الحذف</span>
                                            </Dialog>
                                            <DeleteIcon id='deleteBannerDash' onClick={() => setState({ isShown: true })} />
                                        </Pane>
                                    )}
                                </Component>
                            </div>
                        </Col>
                        )}
                    </Row>
                    <ToastContainer
                        position="bottom-center"
                        autoClose={5000}
                        hideProgressBar={false}
                        newestOnTop={false}
                        closeOnClick
                        rtl={false}
                        pauseOnVisibilityChange
                        draggable
                        pauseOnHover
                    />
                </div>
            )
        }
        else {
            return (
                <div style={{ width: '100%', height: 'calc(100vh - 60px)', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <Lottie style={{ width: 300}}
                        options={{
                            animationData: animation
                        }}
                    />
                </div>
            )
        }
    }
}
export default Category