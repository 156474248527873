import React from 'react';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import { FilePicker, Spinner,toaster } from 'evergreen-ui';
import { Row, Col } from 'react-bootstrap';
import { withStyles } from '@material-ui/styles';
import { withRouter } from 'react-router-dom'
import { Map, TileLayer, Marker } from 'react-leaflet';
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import L from 'leaflet';
import Appbar from '../../common/appBar';
import Cookies from "universal-cookie";
import host from '../../../assets/js/Host';
import axios from 'axios';
const cookies = new Cookies();

var myIcon = new L.Icon({
    iconUrl: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABkAAAApCAYAAADAk4LOAAAFgUlEQVR4Aa1XA5BjWRTN2oW17d3YaZtr2962HUzbDNpjszW24mRt28p47v7zq/bXZtrp/lWnXr337j3nPCe85NcypgSFdugCpW5YoDAMRaIMqRi6aKq5E3YqDQO3qAwjVWrD8Ncq/RBpykd8oZUb/kaJutow8r1aP9II0WmLKLIsJyv1w/kqw9Ch2MYdB++12Onxee/QMwvf4/Dk/Lfp/i4nxTXtOoQ4pW5Aj7wpici1A9erdAN2OH64x8OSP9j3Ft3b7aWkTg/Fm91siTra0f9on5sQr9INejH6CUUUpavjFNq1B+Oadhxmnfa8RfEmN8VNAsQhPqF55xHkMzz3jSmChWU6f7/XZKNH+9+hBLOHYozuKQPxyMPUKkrX/K0uWnfFaJGS1QPRtZsOPtr3NsW0uyh6NNCOkU3Yz+bXbT3I8G3xE5EXLXtCXbbqwCO9zPQYPRTZ5vIDXD7U+w7rFDEoUUf7ibHIR4y6bLVPXrz8JVZEql13trxwue/uDivd3fkWRbS6/IA2bID4uk0UpF1N8qLlbBlXs4Ee7HLTfV1j54APvODnSfOWBqtKVvjgLKzF5YdEk5ewRkGlK0i33Eofffc7HT56jD7/6U+qH3Cx7SBLNntH5YIPvODnyfIXZYRVDPqgHtLs5ABHD3YzLuespb7t79FY34DjMwrVrcTuwlT55YMPvOBnRrJ4VXTdNnYug5ucHLBjEpt30701A3Ts+HEa73u6dT3FNWwflY86eMHPk+Yu+i6pzUpRrW7SNDg5JHR4KapmM5Wv2E8Tfcb1HoqqHMHU+uWDD7zg54mz5/2BSnizi9T1Dg4QQXLToGNCkb6tb1NU+QAlGr1++eADrzhn/u8Q2YZhQVlZ5+CAOtqfbhmaUCS1ezNFVm2imDbPmPng5wmz+gwh+oHDce0eUtQ6OGDIyR0uUhUsoO3vfDmmgOezH0mZN59x7MBi++WDL1g/eEiU3avlidO671bkLfwbw5XV2P8Pzo0ydy4t2/0eu33xYSOMOD8hTf4CrBtGMSoXfPLchX+J0ruSePw3LZeK0juPJbYzrhkH0io7B3k164hiGvawhOKMLkrQLyVpZg8rHFW7E2uHOL888IBPlNZ1FPzstSJM694fWr6RwpvcJK60+0HCILTBzZLFNdtAzJaohze60T8qBzyh5ZuOg5e7uwQppofEmf2++DYvmySqGBuKaicF1blQjhuHdvCIMvp8whTTfZzI7RldpwtSzL+F1+wkdZ2TBOW2gIF88PBTzD/gpeREAMEbxnJcaJHNHrpzji0gQCS6hdkEeYt9DF/2qPcEC8RM28Hwmr3sdNyht00byAut2k3gufWNtgtOEOFGUwcXWNDbdNbpgBGxEvKkOQsxivJx33iow0Vw5S6SVTrpVq11ysA2Rp7gTfPfktc6zhtXBBC+adRLshf6sG2RfHPZ5EAc4sVZ83yCN00Fk/4kggu40ZTvIEm5g24qtU4KjBrx/BTTH8ifVASAG7gKrnWxJDcU7x8X6Ecczhm3o6YicvsLXWfh3Ch1W0k8x0nXF+0fFxgt4phz8QvypiwCCFKMqXCnqXExjq10beH+UUA7+nG6mdG/Pu0f3LgFcGrl2s0kNNjpmoJ9o4B29CMO8dMT4Q5ox8uitF6fqsrJOr8qnwNbRzv6hSnG5wP+64C7h9lp30hKNtKdWjtdkbuPA19nJ7Tz3zR/ibgARbhb4AlhavcBebmTHcFl2fvYEnW0ox9xMxKBS8btJ+KiEbq9zA4RthQXDhPa0T9TEe69gWupwc6uBUphquXgf+/FrIjweHQS4/pduMe5ERUMHUd9xv8ZR98CxkS4F2n3EUrUZ10EYNw7BWm9x1GiPssi3GgiGRDKWRYZfXlON+dfNbM+GgIwYdwAAAAASUVORK5CYII',
    iconSize: [25, 41],
    iconAnchor: [12.5, 41],
    popupAnchor: [0, -41],

})
const styles = theme => ({
    root: {
        width: '100%',

    },
    backButton: {
        marginRight: 30,
        backgroundColor: '#999',
    },
    instructions: {
        marginTop: 30,
        marginBottom: 30,

    },
});



class AddOffice extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            activeStep: 0,
            setActiveStep: 0,
            name: '',
            phone: '',
            password: '',
            address: '',
            image: [],
            location: {
                lat: '',
                lng: '',
            },
            draggable: true,
            spinBtn: false,
        }
    }
    componentDidMount() {
        navigator.geolocation.getCurrentPosition((Position) => {
            this.setState({ location: { lat: Position.coords.latitude, lng: Position.coords.longitude }, haveLocation: true })

            // console.log(Position.coords.latitude, Position.coords.longitude);
        });
    }
    AddOffice() {
        var str = this.state.phone
        var header = { "Content-Type": "application/x-www-form-urlencoded", token: cookies.get("AdminToken") };

        let formData = new FormData();
        formData.append("name", this.state.name);
        formData.append("phone", str.replace(/ +/g, ""));
        formData.append("password", this.state.password);
        formData.append("address", this.state.address);
        formData.append("category_id", this.props.match.params.id);
        formData.append("latitude", this.state.location.lat);
        formData.append("longitude", this.state.location.lng);
        formData.append("file", this.state.image[0]);
        axios({ url: host + `dashbord/office`, method: "POST", data: formData, headers: header })
            .then(response => {
                window.location.href=`/officeDash/${this.props.match.params.id}`;
                this.setState({ spinBtn: false })
            })
            .catch(error => {
                this.setState({ spinBtn: false })
                console.log(error.response.data.message)
                if(error.response.data.message==='Phone number is already in use with another account'){
                toaster.danger("رقم الهاتف مستخدم مسبقا");
                }
                toaster.danger(error.response.data.message);
                
            })
    }
    getSteps() {
        return ['معلومات المكتب', 'اختيار الموقع'];
    }

    getStepContent(stepIndex) {
        switch (this.state.setActiveStep) {
            case 0:
                return (
                    <div id='inputAddContinerOffice'>
                        <Row style={{ margin: 0, badding: 0, direction: 'rtl' }}>
                            <Col id='Addoffice' sm={12} md={6} lg={4}>
                                <input autoComplete='off' type='text' placeholder='اسم المكتب ' id='InputTExtDash260'
                                    onChange={(e) => this.setState({ name: e.target.value })} />
                            </Col>
                            <Col id='Addoffice' sm={12} md={6} lg={4}>
                                <div style={{ direction: 'ltr', position: 'relative' }}>
                                    <PhoneInput
                                        country={'iq'}
                                        value={this.state.phone}
                                        onChange={phone => {
                                            this.setState({ phone })
                                        }}
                                    />
                                </div>
                            </Col>
                            <Col id='Addoffice' sm={12} md={6} lg={4}>
                                <input autoComplete='off' type='password' placeholder='كلمة المرور ' id='InputTExtDash260'
                                    onChange={(e) => this.setState({ password: e.target.value })} />
                            </Col>
                            <Col id='Addoffice' sm={12} md={6} lg={4}>
                                <input autoComplete='off' type='text' placeholder='العنوان' id='InputTExtDash260'
                                    onChange={(e) => this.setState({ address: e.target.value })} />
                            </Col>
                            <Col id='Addoffice' sm={12} md={6} lg={4}>
                                <FilePicker multiple id='InputTExtDash260file' onChange={file => { this.setState({ image: file }) }} />
                            </Col>
                        </Row>
                    </div>
                );
            case 1:
                return (
                    <Map className='mapAddOffice' center={[this.state.location.lat, this.state.location.lng]} zoom={12}>
                        <TileLayer
                            attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                        />
                        <Marker
                            position={[this.state.location.lat, this.state.location.lng]}
                            draggable={this.state.draggable}
                            onDragend={(e) => this.updatePosition(e)}
                            icon={myIcon}>
                        </Marker>
                    </Map>
                );
            default:
                return 'Unknown stepIndex';
        }
    }
    updatePosition(e) {
        this.setState({ location: { lat: e.target._latlng.lat, lng: e.target._latlng.lng }, zoom: e.target.zoom })
    }
    render() {
        const { classes } = this.props;
        //   const [activeStep, setActiveStep] = React.useState(0);
        const steps = this.getSteps();
        const { activeStep } = this.state

        return (

            <div className={classes.root}>
                <Appbar page='AddOffice' />
                <Stepper activeStep={activeStep} alternativeLabel>
                    {steps.map(label => (
                        <Step key={label}>
                            <StepLabel>{label}</StepLabel>
                        </Step>
                    ))}
                </Stepper>
                <div>
                    <div>
                        <Typography className={classes.instructions}>{this.getStepContent(activeStep)}</Typography>
                        <div id='continerAddOffice'>
                            {activeStep === 1 ?
                                <div id='btnAddOffice' onClick={() => this.setState({ setActiveStep: 0, activeStep: 0 })}
                                    className={classes.backButton}> رجوع </div> : null}
                            {activeStep === 0 ? (<div id='btnAddOffice' onClick={() => this.setState({ setActiveStep: 1, activeStep: 1 })}>
                                التالي
                            </div>) : !this.state.spinBtn ?
                                    <div id='btnAddOffice' onClick={() => {
                                        this.setState({ spinBtn: true })
                                        this.AddOffice()
                                    }}> اضافة مكتب</div>
                                    : <div id='btnAddOffice' ><Spinner size={24} /></div>
                            }
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
AddOffice.propTypes = {
    classes: PropTypes.object.isRequired,
}
export default withRouter(withStyles(styles)(AddOffice))
