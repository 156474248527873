import React from 'react';
import Component from "@reactions/component";
// import qs from 'qs';
import { Dialog, Pane, FilePicker } from 'evergreen-ui';
import { ToastContainer, toast } from 'react-toastify';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import Menu from '../common/menuBTN';
import { Col, Row } from 'react-bootstrap';
import AppBar from '../common/appBar';
import { Link } from 'react-router-dom';
import Cookies from "universal-cookie";
import host from '../../assets/js/Host';
import axios from 'axios';
import Lottie from 'lottie-react-web'
import animation from '../../assets/json/tk.json';
const cookies = new Cookies();

class Category extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            cate: [], spin: false,
            nameCate: '',
            image: [],
        }
    }
    componentDidMount() {
        var header = { "Content-Type": "application/x-www-form-urlencoded", token: cookies.get("AdminToken") };
        axios.get(host + `category/mains`, { headers: header })
            .then(res => {
                // console.log(res.data.data.categories);
                this.setState({ cate: res.data.data.categories, spin: true })
            })
            .catch(error => { console.log(error) 
                this.setState({spin: true})})


    }
    deletCate(id) {
        var header = { "Content-Type": "application/x-www-form-urlencoded", token: cookies.get("AdminToken") };
        axios.delete(host + `category/${id}`, { headers: header })
            .then(response => {
                toast('تم الحذف بنجاح', {
                    position: "bottom-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true
                });
                this.componentDidMount();
            })
            .catch(error => { console.log(error) })
    }
    editCate(id) {
        var header = { "Content-Type": "application/x-www-form-urlencoded", token: cookies.get("AdminToken") };
        let formData = new FormData();
        formData.append("name", this.state.nameCate);
        formData.append("file", this.state.image[0]);
        axios({ url: host + `category/edit/${id}`, method: "PUT", data: formData, headers: header })
            .then(res => {
                // console.log(res.data);
                
                this.setState({ image: '' })
                toast('تم التعديل بنجاح', {
                    position: "bottom-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true
                });
                this.componentDidMount();
            })
            .catch(error => { console.log(error) })
    }
    render() {
        if (this.state.spin) {
            return (
                <div>
                    <AppBar page='categoryDash' />
                    <Row style={{ direction: 'rtl', padding: 0 }}>
                        {this.state.cate.map(cat =>
                            <Col key={cat.id} id='ColCate' sm={12} md={6} lg={4}>
                                <div id='CardCategory'>
                                    <Menu acti={[
                                        {
                                            icon: <Component initialState={{ isShown: false }}>
                                                {({ state, setState }) => (
                                                    <Pane>
                                                        <Dialog
                                                            isShown={state.isShown}
                                                            title="حذف صنف "
                                                            intent="danger"
                                                            onCloseComplete={() => setState({ isShown: false })}
                                                            confirmLabel="حذف"
                                                            cancelLabel="الغاء"
                                                            onConfirm={() => {
                                                                setState({ isShown: false })
                                                                this.deletCate(cat.id)
                                                            }}
                                                        >
                                                            <span id='msgDelete'> هل انت متأكد من عملية الحذف</span>
                                                        </Dialog>
                                                        <DeleteIcon onClick={() => setState({ isShown: true })} />
                                                    </Pane>
                                                )}
                                            </Component>, name: 'حذف'
                                        },
                                        {
                                            icon: <Component initialState={{ isShown: false }}>
                                                {({ state, setState }) => (
                                                    <Pane>
                                                        <Dialog
                                                            isShown={state.isShown}
                                                            title='تعديل ماده'
                                                            onCloseComplete={() => setState({ isShown: false })}
                                                            confirmLabel="تعديل"
                                                            cancelLabel="الغاء"
                                                            onConfirm={() => {
                                                                // console.log(state.img);
                                                                this.editCate(cat.id)
                                                                setState({ isShown: false })
                                                            }}
                                                        >
                                                            <div id='inputAddContiner'>
                                                                <div id={'ContinerInPut'} >
                                                                    <label>اسم القسم</label>
                                                                    <input autoComplete='off' type='text' placeholder='اسم القسم' id='InputTExtDash1'
                                                                        onChange={(e) => this.setState({ nameCate: e.target.value })} />
                                                                </div>
                                                                <div id={'ContinerInPut'}>
                                                                    <label>اختر صورة</label>
                                                                    <FilePicker multiple id='InputTExtDash2' onChange={file => { this.setState({ image: file }) }} />
                                                                </div>
                                                            </div>
                                                        </Dialog>
                                                        <EditIcon onClick={() => { setState({ isShown: true }) }} />
                                                    </Pane>
                                                )}
                                            </Component>, name: 'تعديل'
                                        },

                                    ]} />
                                    <Link to={`/officeDash/${cat.id}`}>
                                    <div style={{ height: 200 }}>
                                        <img id='imgCategory' src={host + 'files/' + cat.photo} alt='img' />
                                    </div>
                                    <div id='nameCategory'>{cat.name}</div>
                                    </Link>
                                </div>
                            </Col>
                        )} 
                    </Row>
                    <ToastContainer
                        position="bottom-center"
                        autoClose={5000}
                        hideProgressBar={false}
                        newestOnTop={false}
                        closeOnClick
                        rtl={false}
                        pauseOnVisibilityChange
                        draggable
                        pauseOnHover
                    />
                </div>
            )
        }
        else {
            return (
                <div style={{ width: '100%', height: 'calc(100vh - 60px)', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <Lottie style={{ width: 300}}
                        options={{
                            animationData: animation
                        }}
                    />
                </div>
            )
        }
    }
}
export default Category