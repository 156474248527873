import React from 'react';
import Context from '../../assets/js/Context';
import Cookies from "universal-cookie";
import host from '../../assets/js/Host';
import axios from 'axios';
import { Row, Col } from 'react-bootstrap';
import Lottie from 'lottie-react-web'
import animation from '../../assets/json/tk.json';
import Navbar from '../common/navbar';
import Footer from '../common/footer';
import { Link } from 'react-router-dom';
import Disable from '../common/Disabled';
const cookies = new Cookies();

class Home extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            spin: false,
            offices: [],
        }
    }

    componentDidMount() {
        var header = { "Content-Type": "application/x-www-form-urlencoded", token: cookies.get("token") };
        axios.get(host + `customers/category/?category_id=${this.props.match.params.id}`, { headers: header })
            .then(res => {
                // console.log(res.data.data.items);
                this.setState({ offices: res.data.data.items, spin: true })
            })
            .catch(error => {
                console.log(error)
                this.setState({ spin: true })
            })


    }

    render() {
        if (this.state.spin) {
            return (
                <Context.Consumer>
                    {ctx => {
                        if (ctx.value.isActive) {
                            return (
                                <div style={{ backgroundColor: '#ecf0f1' }}>
                                    <Navbar />
                                    <div id='TItIMG'>
                                        <div id='TItIMG1'>
                                            <img id='IM11' src={localStorage.getItem('IMCAT')} alt='cate' />
                                            <div id='IM22' ></div>
                                        </div>

                                        <div id='TItIMG2'>
                                            <h1>{localStorage.getItem('NAMECAT')}</h1>
                                        </div>
                                    </div>
                                    <div id='continerItemCate'>
                                        <Row style={{ margin: 0, padding: 0, direction: 'rtl' }}>
                                            {this.state.offices.map(office =>
                                                <Col key={office.id} id='ColCate' xs={6} sm={6} md={4} lg={3} xl={2}>
                                                    <div id='sliderCateImgContiner1' onClick={() => localStorage.setItem('page', 'itemCate')}>
                                                        <Link to={`/ItemDesc/${office.id}`}  onClick={()=>localStorage.setItem('IdCAT',this.props.match.params.id)}>
                                                            <div id='ImgBestOffers'>
                                                                <img id='ImgBestOffers'
                                                                    src={host + 'files/' + office.cove_photo} style={{ cursor: 'pointer' }} alt='cate' />
                                                            </div>
                                                            <div style={{ padding: 5, textAlign: 'center' }}>
                                                                <h6 style={{ direction: 'rtl', color: '#ffa450', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>{office.name}</h6>
                                                                <p style={{ direction: 'rtl', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>{office.price}</p>
                                                            </div>
                                                        </Link>
                                                    </div>
                                                </Col>
                                            )}

                                        </Row>
                                    </div>
                                    <Footer />
                                </div>
                            )
                        }
                        else {
                            return (
                                <Disable />
                            )
                        }
                    }}
                </Context.Consumer>
            )
        }
        else {
            return (
                <div style={{ width: '100%', height: 'calc(100vh - 60px)', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <Lottie style={{ width: 300 }}
                        options={{
                            animationData: animation
                        }}
                    />
                </div>
            )
        }
    }
}
export default Home