import React from 'react';
import Component from "@reactions/component";
import { Pane, Dialog, SideSheet, Position, FilePicker, Spinner } from 'evergreen-ui';
import { Form } from 'react-bootstrap';
import OpenWithIcon from '@material-ui/icons/Info';
import EditIcon from '@material-ui/icons/Edit';
import { ToastContainer, toast } from 'react-toastify';
import AppBar from '../common/appBar';
import Cookies from "universal-cookie";
import host from '../../assets/js/Host';
import axios from 'axios';
import Lottie from 'lottie-react-web'
import animation from '../../assets/json/tk.json';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import MaterialDatatable from "material-datatable";
import { createTheme, MuiThemeProvider } from '@material-ui/core/styles';

const cookies = new Cookies();
const columns = [

    { field: "delet", name: "حذف", options: { width: 100, filter: true, sort: false, } },
    { field: "edit", name: "تعديل", options: { width: 100, filter: true, sort: false, } },
    { field: "desc", name: "التفاصيل", options: { width: 100, filter: true, sort: false, } },
    { field: "type", name: "النوع", options: { filter: true, sort: false, } },
    { field: "price", name: "سعر", options: { filter: true, sort: false, } },
    { field: "name", name: "الاسم", options: { filter: true, sort: true, } },
    { field: "img", name: "صوره", options: { width: 150, filter: true, sort: false, } },
];
const options = {
    selectableRows: false,
    responsive: 'scroll',
    rowCursorHand: false,
    sort: false,
    filter: false,
    print: false,
    download: false,
    textLabels: {
        body: { noMatch: " لم يتم العثور على سجلات مطابقة", toolTip: "فرز", },
        pagination: { next: "الصفحة التالية", previous: "الصفحة السابقة", rowsPerPage: "عدد الصفوف", displayRows: "من", },
        toolbar: { search: "بحث", downloadCsv: "تنزيل", print: "Print", viewColumns: " التحكم بالاعمدة", filterTable: "فلتر", },
        filter: { all: "الكل", title: "فلتر", reset: "إعادة تعيين", },
        viewColumns: { title: "عرض الأعمدة", titleAria: "إظهار / إخفاء أعمدة الجدول", },
    }
}
class Category extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            office: [],
            cate: [],
            spin: false,
            nameCate: '',
            cateId: '',
            image: [],
        }
    }

    componentDidMount() {
        var header = { "Content-Type": "application/x-www-form-urlencoded", token: cookies.get("AdminToken") };

        axios.get(host + `customers/category/?category_id=${this.props.match.params.id}`, { headers: header })
            .then(res => {
                // console.log(res.data.data.items);

                let arr = [];
                for (let i = 0; i < res.data.data.items.length; i++) {
                    let obj = {
                        img: <img style={{ width: 100, height: 100 }} src={host + 'files/' + res.data.data.items[i].cove_photo} alt='img' />,
                        name: res.data.data.items[i].name,
                        price: res.data.data.items[i].price,
                        type: res.data.data.items[i].type === 0 ? 'قطعه' : 'كغ',
                        desc: <Component initialState={{ isShown: false }}>
                            {({ state, setState }) => (
                                <Pane>
                                    <Dialog
                                        isShown={state.isShown}
                                        title='التفاصيل'
                                        onCloseComplete={() => setState({ isShown: false })}
                                        hasFooter={false}
                                    >
                                        <div style={{ display: 'flex', direction: 'rtl', height: 200 }}>
                                            {res.data.data.items[i].description}
                                        </div>
                                    </Dialog>
                                    <OpenWithIcon style={{ cursor: 'pointer', color: '#5ad25a' }} onClick={() => { setState({ isShown: true }) }} />
                                </Pane>
                            )}
                        </Component>,
                        edit: <Component initialState={{
                            isShown: false, spinerBtn: false, image: [], name: '', price:'', description:'', type: ''
                        }}>
                            {({ state, setState }) => (
                                <React.Fragment>
                                    <SideSheet
                                        position={Position.LEFT}
                                        isShown={state.isShown}
                                        onCloseComplete={() => setState({ isShown: false })}
                                    >
                                        <Pane margin={40}>

                                            <React.Fragment  >

                                                <div>
                                                    <div id='titleAddAppBar'>تعديل مادة</div>
                                                    <div id='inputAddContiner'>
                                                        <div id={'ContinerInPut'} >
                                                            <label>اسم المادة </label>
                                                            <input autoComplete='off' type='text' placeholder='اسم المادة ' id='InputTExtDash1'
                                                                value={state.name} onChange={(e) => setState({ name: e.target.value })} />
                                                        </div>
                                                        <div id={'ContinerInPut'} >
                                                            <label>السعر</label>
                                                            <input autoComplete='off' type='number' placeholder='السعر' id='InputTExtDash1'
                                                                value={state.price} onChange={(e) => setState({ price: e.target.value })} />
                                                        </div>
                                                        <div id={'ContinerInPut'}>
                                                            <label>النوع</label>
                                                            <Form.Control as="select" id='InputTExtDash1'
                                                                onChange={(even) => {
                                                                    if (even.target.value !== 'Select') {
                                                                        setState({ type: even.target.value })
                                                                    }
                                                                }}>
                                                                <option value='Select'>اختيار</option>
                                                                <option value={1}>كغ</option>
                                                                <option value={0} >قطعة</option>
                                                            </Form.Control>
                                                        </div>
                                                        <div id={'ContinerInPut'}>
                                                            <label>التفاصيل </label>
                                                            <textarea id='textAreaAdd' cols="100" placeholder="التفاصيل "
                                                                value={state.description} onChange={(e) => setState({ description: e.target.value })} />
                                                        </div>
                                                        <div id={'ContinerInPut'}>
                                                            <label>اختر صورة</label>
                                                            <FilePicker multiple id='InputTExtDash2' onChange={file => { setState({ image: file }) }} />
                                                        </div>
                                                    </div>
                                                    <div id='btnAddItemContiner'>
                                                        {!state.spinerBtn ? (
                                                            <div id='btnAddItem' onClick={() => {
                                                                setState({ spinerBtn: true })
                                                                var header = { "Content-Type": "application/x-www-form-urlencoded", token: cookies.get("AdminToken") };

                                                                let formData = new FormData();
                                                                formData.append("name", state.name);
                                                                formData.append("price", state.price);
                                                                formData.append("type", state.type);
                                                                formData.append("body", 'body');
                                                                formData.append("description", state.description);
                                                                formData.append("category_id", this.props.match.params.id);
                                                                formData.append("file", state.image[0]);
                                                                axios({ url: host + `items/edit/${res.data.data.items[i].id}`, method: "PUT", data: formData, headers: header })
                                                                    .then(response => {
                                                                        window.location.reload();
                                                                        setState({ spinerBtn: false })

                                                                    })
                                                                    .catch(error => {
                                                                        console.log(error)
                                                                        setState({ spinerBtn: false })
                                                                    })
                                                            }}>تعديل</div>

                                                        ) : (
                                                            <div id='btnAddItem'><Spinner size={24} /></div>

                                                        )}
                                                    </div>

                                                </div>

                                            </React.Fragment  >
                                        </Pane>
                                    </SideSheet>


                                    <EditIcon style={{ cursor: 'pointer', color: '#338eff' }} onClick={() => {
                                        axios.get(host + `items/item/${res.data.data.items[i].id}`, { headers: header })
                                            .then(res => {
                                                console.log(res.data.data.item);
                                                setState({
                                                    name: res.data.data.item.name,
                                                    price: res.data.data.item.price,
                                                    type: res.data.data.item.type,
                                                    description: res.data.data.item.description,
                                                    spin: false,
                                                });

                                            })
                                            .catch(error => {
                                                console.log(error)
                                                this.setState({ spin: true })
                                            })
                                        setState({ isShown: true })
                                    }} />
                                </React.Fragment>
                            )}
                        </Component>,
                        delet: <Component initialState={{ isShown: false }}>
                            {({ state, setState }) => (
                                <Pane>
                                    <Dialog
                                        isShown={state.isShown}
                                        title="حذف ماده "
                                        intent="danger"
                                        onCloseComplete={() => setState({ isShown: false })}
                                        confirmLabel="حذف"
                                        cancelLabel="الغاء"
                                        onConfirm={() => {
                                            setState({ isShown: false })
                                            this.DeleteOffice(res.data.data.items[i].id)
                                        }}
                                    >
                                        <span id='msgDelete'> هل انت متأكد من عملية الحذف</span>
                                    </Dialog>

                                    <DeleteForeverIcon style={{ marginLeft: 20, color: '#e96b6b', cursor: 'pointer' }}
                                        onClick={() => setState({ isShown: true })} />
                                </Pane>
                            )}
                        </Component>
                    };
                    arr.push(obj);
                }
                this.setState({
                    office: arr, spin: true
                });
            })
            .catch(error => {
                console.log(error)
                this.setState({ spin: true })
            })
    }
    getMuiTheme = () => createTheme({
        overrides: {
            MaterialDatatableBodyCell: {
                root: { textAlign: 'right', },
            },
            MuiTableCell: {
                root: { textAlign: 'right', },
                // head: {  backgroundColor: "#FF0000", }
            },
        }
    })

    DeleteOffice(id) {
        var header = { "Content-Type": "application/x-www-form-urlencoded", token: cookies.get("AdminToken") };
        axios.delete(host + `items/item/${id}`, { headers: header })
            .then(res => {
                toast('تم الحذف بنجاح', {
                    position: "bottom-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true
                });
                this.componentDidMount();
            })
            .catch(error => { console.log(error) })

    }

    render() {
        if (this.state.spin) {
            return (
                <div>
                    <AppBar page='itemOffice' CAtid={this.props.match.params.id} />
                    <MuiThemeProvider
                        theme={this.getMuiTheme()}>
                        <MaterialDatatable data={this.state.office} columns={columns} options={options} />
                    </MuiThemeProvider>
                    <ToastContainer
                        position="bottom-center"
                        autoClose={5000}
                        hideProgressBar={false}
                        newestOnTop={false}
                        closeOnClick
                        rtl={false}
                        pauseOnVisibilityChange
                        draggable
                        pauseOnHover
                    />
                </div>
            )
        }
        else {
            return (
                <div style={{ width: '100%', height: 'calc(100vh - 60px)', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <Lottie style={{ width: 300 }}
                        options={{
                            animationData: animation
                        }}
                    />
                </div>
            )
        }
    }
}
export default Category