import React from 'react';
import Context from '../../assets/js/Context';
import Component from "@reactions/component";
import { Pane, Dialog } from 'evergreen-ui';
import CancelIcon from '@material-ui/icons/Cancel';
import ListIcon from '@material-ui/icons/List';
import { ToastContainer, toast } from 'react-toastify';
import Cookies from "universal-cookie";
import { Tabs, Tab } from 'react-bootstrap';
import Paper from '@material-ui/core/Paper';
import host from '../../assets/js/Host';
import axios from 'axios';
import Lottie from 'lottie-react-web'
import Navbar from '../common/navbar';
import * as moment from "moment";
import animation from '../../assets/json/tk.json';
import MaterialDatatable from "material-datatable";
import { createTheme, MuiThemeProvider } from '@material-ui/core/styles';
import Disable from '../common/Disabled';
const columnsW = [
    { field: "cancel", name: "الغاء ", options: { width: 100, filter: true, sort: true, } },
    { field: "date", name: "تاريخ ", options: { width: 200, filter: true, sort: true, } },
    { field: "price", name: "السعر", options: { filter: true, sort: true, } },
    { field: "item", name: "المواد", options: { filter: true, sort: true, } },
];
const columnsD = [
    { field: "date", name: "تاريخ ", options: { width: 200, filter: true, sort: true, } },
    { field: "price", name: "السعر", options: { filter: true, sort: true, } },
    { field: "item", name: "المواد", options: { filter: true, sort: true, } },
];
const options = {
    selectableRows: false,
    responsive: 'scroll',
    rowCursorHand: false,
    sort: false,
    print: false,
    filter: false,
    download: false,
    textLabels: {
        body: {
            noMatch: " لم يتم العثور على سجلات مطابقة",
            toolTip: "فرز",
        },
        pagination: {
            next: "الصفحة التالية",
            previous: "الصفحة السابقة",
            rowsPerPage: "عدد الصفوف",
            displayRows: "من",
        },
        toolbar: {
            search: "بحث",
            downloadCsv: "تنزيل",
            print: "Print",
            viewColumns: " التحكم بالاعمدة",
            filterTable: "فلتر",
        },
        filter: {
            all: "الكل",
            title: "فلتر",
            reset: "إعادة تعيين",
        },
        viewColumns: {
            title: "عرض الأعمدة",
            titleAria: "إظهار / إخفاء أعمدة الجدول",
        },

    }
}
const cookies = new Cookies();

class Category extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            Watting: [],
            Done: [],
            Cancel: [],
            spin: false,

        }
    }
    getMuiTheme = () => createTheme({
        overrides: {
            MaterialDatatableBodyCell: { root: { textAlign: 'right', } },
            MuiTableCell: { root: { textAlign: 'right' } },
        }
    })


    componentDidMount() {
        var header = { "Content-Type": "application/x-www-form-urlencoded", Authorization: cookies.get("token") };
        axios.get(host + `orders/orders/my?status=0`, { headers: header })
            .then(res => {
                // console.log(res.data.data.orders);
                let arr = [];
                for (let i = 0; i < res.data.data.orders.length; i++) {
                    // console.log(res.data.data.users);
                    let obj = {
                        item: <Component initialState={{ isShown: false, data: res.data.data.orders[i].items }}>
                            {({ state, setState }) => (
                                <Pane>
                                    <Dialog
                                        isShown={state.isShown}
                                        title="المواد"
                                        onCloseComplete={() => setState({ isShown: false })}
                                        hasFooter={false}

                                    >
                                        < table id='students' >
                                            <thead>
                                                <tr>
                                                    <th>الرقم</th>
                                                    <th>الصنف</th>
                                                    <th>العدد/الوزن</th>
                                                </tr>
                                            </thead>
                                            <tbody >
                                                {state.data.map((item, i) => (
                                                    <tr key={i}>
                                                        <td style={{ width: 60 }} >{i + 1}</td>
                                                        <td  >{item.name}</td>
                                                        <td style={{ width: 100 }} >{item.count} {item.type === 0 ? 'قطعه' : 'كغ'}</td>
                                                    </tr>

                                                ))}
                                            </tbody>

                                        </table>
                                    </Dialog>
                                    < ListIcon style={{ color: 'blue', cursor: 'pointer' }} onClick={() => setState({ isShown: true })} />
                                </Pane>
                            )}
                        </Component>,
                        price: res.data.data.orders[i].price,
                        date: moment(res.data.data.orders[i].createdAt).format('DD/MM/YYYY'),
                        cancel: <Component initialState={{ isShown: false }}>
                            {({ state, setState }) => (
                                <Pane>
                                    <Dialog
                                        isShown={state.isShown}
                                        title="رفض الطلب"
                                        intent="danger"
                                        onCloseComplete={() => setState({ isShown: false })}
                                        confirmLabel="تاكيد"
                                        cancelLabel="الغاء"
                                        onConfirm={() => {
                                            axios.delete(host + `orders/order/${res.data.data.orders[i].id}`, { headers: header })
                                                .then(response => {
                                                    setState({ isShown: false })
                                                    toast('تم الغاء الطلب', {
                                                        position: "bottom-center",
                                                        autoClose: 5000,
                                                        hideProgressBar: false,
                                                        closeOnClick: true,
                                                        pauseOnHover: true,
                                                        draggable: true
                                                    });
                                                    this.componentDidMount()
                                                }).catch(error => { console.log(error) })
                                        }}
                                    >
                                        هل انت متاكد من عملية رفض الطلب
                                    </Dialog>
                                    <CancelIcon style={{ color: '#d26a74', cursor: 'pointer' }} onClick={() => setState({ isShown: true })} />
                                </Pane>
                            )}
                        </Component>,

                    };
                    arr.push(obj);
                }
                this.setState({
                    Watting: arr, spin: true
                });
            })
            .catch(error => {
                console.log(error)
                this.setState({ spin: true })
            })
        ////////////////////////// done////////////////////////////

        axios.get(host + `orders/orders/my?status=1`, { headers: header })
            .then(res => {
                // console.log(res.data.data.orders);
                let arr = [];
                for (let i = 0; i < res.data.data.orders.length; i++) {
                    // console.log(res.data.data.users);
                    let obj = {
                        item: <Component initialState={{ isShown: false, data: res.data.data.orders[i].items }}>
                            {({ state, setState }) => (
                                <Pane>
                                    <Dialog
                                        isShown={state.isShown}
                                        title="المواد"
                                        onCloseComplete={() => setState({ isShown: false })}
                                        hasFooter={false}

                                    >
                                        < table id='students' >
                                            <thead>
                                                <tr>
                                                    <th>الرقم</th>
                                                    <th>الصنف</th>
                                                    <th>العدد/الوزن</th>
                                                </tr>
                                            </thead>
                                            <tbody >
                                                {state.data.map((item, i) => (
                                                    <tr key={i}>
                                                        <td style={{ width: 60 }} >{i + 1}</td>
                                                        <td  >{item.name}</td>
                                                        <td style={{ width: 100 }} >{item.count} {item.type === 0 ? 'قطعه' : 'كغ'}</td>
                                                    </tr>

                                                ))}
                                            </tbody>

                                        </table>
                                    </Dialog>
                                    < ListIcon style={{ color: 'blue', cursor: 'pointer' }} onClick={() => setState({ isShown: true })} />
                                </Pane>
                            )}
                        </Component>,
                        price: res.data.data.orders[i].price,
                        date: moment(res.data.data.orders[i].createdAt).format('DD/MM/YYYY'),

                    };
                    arr.push(obj);
                }
                this.setState({
                    Done: arr, spin: true
                });
            })
            .catch(error => {
                console.log(error)
                this.setState({ spin: true })
            })
        // ///////////////////////////////// cancel /////////////////////////////
        axios.get(host + `orders/orders/my?status=2`, { headers: header })
            .then(res => {
                // console.log(res.data.data.orders);
                let arr = [];
                for (let i = 0; i < res.data.data.orders.length; i++) {
                    // console.log(res.data.data.users);
                    let obj = {
                        item: <Component initialState={{ isShown: false, data: res.data.data.orders[i].items }}>
                            {({ state, setState }) => (
                                <Pane>
                                    <Dialog
                                        isShown={state.isShown}
                                        title="المواد"
                                        onCloseComplete={() => setState({ isShown: false })}
                                        hasFooter={false}

                                    >
                                        < table id='students' >
                                            <thead>
                                                <tr>
                                                    <th>الرقم</th>
                                                    <th>الصنف</th>
                                                    <th>العدد/الوزن</th>
                                                </tr>
                                            </thead>
                                            <tbody >
                                                {state.data.map((item, i) => (
                                                    <tr key={i}>
                                                        <td style={{ width: 60 }} >{i + 1}</td>
                                                        <td  >{item.name}</td>
                                                        <td style={{ width: 100 }} >{item.count} {item.type === 0 ? 'قطعه' : 'كغ'}</td>
                                                    </tr>

                                                ))}
                                            </tbody>

                                        </table>
                                    </Dialog>
                                    < ListIcon style={{ color: 'blue', cursor: 'pointer' }} onClick={() => setState({ isShown: true })} />
                                </Pane>
                            )}
                        </Component>,
                        price: res.data.data.orders[i].price,
                        date: moment(res.data.data.orders[i].createdAt).format('DD/MM/YYYY'),

                    };
                    arr.push(obj);
                }
                this.setState({
                    Cancel: arr, spin: true
                });
            })
            .catch(error => {
                console.log(error)
                this.setState({ spin: true })
            })
    }

    render() {
        if (this.state.spin) {
            return (
                <Context.Consumer>
                    {ctx => {
                        if (ctx.value.isActive) {
                            return (
                                <div>
                                    <Navbar />
                                    <center style={{ margin: 20 }}><h5>سجل الطلبات السابقة</h5></center>
                                    <Paper className={'classesroot'} >
                                        <Tabs defaultActiveKey={this.state.activetap} id="uncontrolled-tab-example" style={{ backgroundColor: '#fff', }}>
                                            <Tab eventKey='wating' title="انتظار" tabClassName='wating1' onClick={() => this.setState({ activetap: 'wating' })}>
                                                <div className='DataTableContiner'>
                                                    <MuiThemeProvider
                                                        theme={this.getMuiTheme()}>
                                                        <MaterialDatatable data={this.state.Watting} columns={columnsW} options={options} />
                                                    </MuiThemeProvider>
                                                </div>
                                            </Tab>
                                            <Tab eventKey="done" title="مكتمل " tabClassName='done1' onClick={() => this.setState({ activetap: 'done' })}>
                                                <div className='DataTableContiner'>
                                                    <MuiThemeProvider
                                                        theme={this.getMuiTheme()}>
                                                        <MaterialDatatable data={this.state.Done} columns={columnsD} options={options} />
                                                    </MuiThemeProvider>
                                                </div>
                                            </Tab>
                                            <Tab eventKey="cancel" title="ملغى " tabClassName='cancel1' onClick={() => this.setState({ activetap: 'cancel' })}>
                                                <div className='DataTableContiner'>
                                                    <MuiThemeProvider
                                                        theme={this.getMuiTheme()}>
                                                        <MaterialDatatable data={this.state.Cancel} columns={columnsD} options={options} />
                                                    </MuiThemeProvider>
                                                </div>
                                            </Tab>
                                        </Tabs>
                                    </Paper>
                                    <ToastContainer
                                        position="bottom-left"
                                        autoClose={10000}
                                        hideProgressBar={false}
                                        newestOnTop={false}
                                        closeOnClick
                                        rtl={false}
                                        pauseOnVisibilityChange
                                        draggable
                                        pauseOnHover
                                    />
                                </div>
                            )
                        }
                        else {
                            return (
                                <Disable />
                            )
                        }
                    }}
                </Context.Consumer>
            )
        }
        else {
            return (
                <div style={{ width: '100%', height: 'calc(100vh - 60px)', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <Lottie style={{ width: 300 }}
                        options={{
                            animationData: animation
                        }}
                    />
                </div>
            )
        }
    }
}
export default Category