import React from 'react';
import { Row, Col } from 'react-bootstrap';
import FacebookIcon from '@material-ui/icons/Facebook';
import InstagramIcon from '@material-ui/icons/Instagram';
class Footer extends React.Component {
    render() {
        return (
            <div id='footerContiner'>
                <div id='footerRedContiner'>
                    <Row style={{ margin: 0, padding: 0, direction: 'rtl' }}>
                        <Col id='ColFooter1' md={12} lg={4}>
                            <h2 style={{ color: '#fff' }}>حولنا</h2>
                            <h5 style={{ color: '#fff', textAlign: 'center' }}>حلويات ومعجنات ابو رافل تفتخر بتقديمها افخم وافضل المنتجات من قوالب كيك عيد الميلاد والاعراس والحلويات الشرقية باجود المواد الاولية وامهر الكوادر واحدث المكائن نتطلع دائما الى ارضاء زبائننا الكرام بتقديم الخدمات التي تليق بهم . حلويات ابو رافل تأسست سنه 1984 </h5>
                        </Col>
                        <Col id='ColFooter1' md={12} lg={4}>
                            <h2 style={{ color: '#fff' }}>فروعنا</h2>
                            <a href="tel:07901840070"><h5 style={{ color: '#fff'}}>فرع الكرادة خارج :07901840070</h5></a>
                            <a href="tel:07730840071"><h5 style={{ color: '#fff'}}>فرع المنصور :07730840071</h5></a>
                            <a href="tel:07730840073"><h5 style={{ color: '#fff'}}>فرع زيونة :07730840073</h5></a>
                            <a href="tel:07730840075"><h5 style={{ color: '#fff'}}>فرع الاعظمية :07730840075</h5></a>
                        </Col>
                        <Col id='ColFooter3' md={12} lg={4}>
                            <div>
                                <div>
                                    <h5 style={{ color: '#fff',textAlign: 'center' }}>هاتف المصنع</h5>
                                    <a href="tel:07832020006"><h5 style={{ color: '#fff',textAlign: 'center' }}>07832020006</h5></a>
                                    
                                </div>
                                <a href='https://www.instagram.com/aburafil1984/'>
                                    <InstagramIcon id='iconSochil' />
                                </a>
                                <a href='https://www.facebook.com/aburafil.iq'>
                                    <FacebookIcon id='iconSochil' />
                                </a>
                            </div>
                        </Col>
                    </Row>

                </div>
                <center style={{ marginTop: 12 }}>حلويات ابو رافل . 2022 كافة الحقوق محفوظة © </center>
            </div>
        )
    }
}
export default Footer